import React from 'react'
import SessionConnector, { ConnectorProps as SessionProps } from 'containers/Session'
import Auth from './Auth'
import Account from './Account'
import styles from './Account.module.scss'
import classNames from 'classnames'

type Props = SessionProps

const Authentication = (props: Props) => {
  const { isSessionReady, authenticated } = props

  if (!isSessionReady) {
    return null
  }

  return (
    <div
      className={classNames(styles.accountContainer, {
        [styles.accountContainerAuthenticated]: authenticated,
      })}
      data-type='account-container'
    >
      {authenticated ? <Account /> : <Auth />}
    </div>
  )
}
export default SessionConnector(Authentication)
