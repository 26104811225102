//@ts-nocheck
import styled from 'styled-components'
import { space } from 'styled-system'
import { timingFunctions } from 'polished'
import { PlainLink } from './A'
export const ContextMenu = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  line-height: 2;
  opacity: ${(props) => (props.active ? 1 : 0)};
  pointer-events: ${(props) => (props.active ? 'auto' : 'none')};
  transition: ${(props) =>
    props.active
      ? `0.15s ${timingFunctions('easeInOutQuad')} opacity, 0.2s ${timingFunctions(
          'easeInOutQuad'
        )} transform`
      : `0.1s ${timingFunctions('easeInOutQuad')} opacity, 0.15s ${timingFunctions(
          'easeInOutQuad'
        )} transform`};
  will-change: opacity;
  outline: none;
  transform: ${(props) => (props.active ? 'translateY(0)' : 'translateY(-3%)')};
`
ContextMenu.defaultProps = {
  p: 4,
}
export const ContextMenuItem = styled(PlainLink)`
  white-space: nowrap;
  display: block;
  background-color: transparent;
  border: none;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  color: #666;
  &:hover {
    color: ${(props) => props.theme.colors.black};
  }
`
export const ContextMenuActive = `
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
  transition: 0.15s ${timingFunctions('easeInOutQuad')} opacity,
    0.2s ${timingFunctions('easeInOutQuad')} transform;
`
export default ContextMenu
