import React from 'react'
import NProgress from 'nprogress'
import Router from 'next/router'
import FlashMessage from 'components/FlashMessage'
import TakeoverIndicator from '../TakeoverIndicator'
import Navigation from './Navigation'
// https://ricostacruz.com/nprogress/ slim JS loading bar
// We are connecting it to page ( clientside ) transitions in NEXTjs
// to give user some indication of an ACTION taking place
NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: true,
  trickleSpeed: 300,
})

Router.onRouteChangeStart = (url) => NProgress.start()

Router.onRouteChangeComplete = () => NProgress.done()

Router.onRouteChangeError = () => NProgress.done()

export default (props: {
  siteContext: string
}): React.ReactElement<React.ComponentProps<any>, any> => {
  const { siteContext } = props
  return (
    <nav data-section='studio-navigation'>
      <TakeoverIndicator />
      <Navigation siteContext={siteContext} />
      <FlashMessage />
    </nav>
  )
}