import React, { FC } from 'react'
import UserPreferences, { ConnectorProps as UserPreferencesProps } from 'connectors/UserPreferences'
import User from './User/User'
import Cart from './Cart/Cart'
import styles from './IconActions.module.scss'
import SearchIcon from 'svg/search-icon.svg'
import { useHeaderV2Context } from '../HeaderV2Context'
import StudioLink from 'components/HeaderV2/IconActions/Studio/StudioLink'
import FavoritesIcon from './Favorites/FavoritesIcon'
import HamburgerMenuIcon from 'svg/hamburger.svg'
import CloseIcon from 'svg/close-icon.svg'
import CountryFlagIcon from './CountryFlag/CountryFlagIcon'
type IconActionProps = UserPreferencesProps & { modalName: string }
const IconActions: FC<IconActionProps> = ({ userPreferences, modalName }) => {
  const { setSelectedCategory, setMobileHeaderConfig, mobileHeaderConfig } = useHeaderV2Context()

  const handleToggleHamburgerMenu = () => {
    setMobileHeaderConfig((previousMobileHeaderConfig) => ({
      ...previousMobileHeaderConfig,
      mobileMenuContent: 'categories',
      isMobileMenuOpen: !previousMobileHeaderConfig.isMobileMenuOpen,
    }))
    setSelectedCategory(null)
  }

  const handleSearchClick = () => {
    setMobileHeaderConfig((previousMobileHeaderConfig) => ({
      ...previousMobileHeaderConfig,
      mobileTopHeader: 'search',
      mobileMenuContent: 'search',
      isMobileMenuOpen: true,
    }))
  }

  // only to apply loading at the same time as the other icons
  if (!userPreferences?.country) {
    return null
  }

  return (
    <div className={styles.icons}>
      <div onClick={handleSearchClick} className={styles.searchIconContainer}>
        <SearchIcon />
      </div>
      <StudioLink />
      <div className={styles.mobileHiddenIconContainer}>
        <CountryFlagIcon modalName={modalName} />
      </div>
      <FavoritesIcon />
      <User />
      <Cart />
      <div className={styles.hamburgerBox} onClick={handleToggleHamburgerMenu}>
        {mobileHeaderConfig.isMobileMenuOpen ? (
          <CloseIcon className={styles.closeHamburgerIcon} />
        ) : (
          <HamburgerMenuIcon className={styles.hamburguerIcon} />
        )}
      </div>
    </div>
  )
}

export default UserPreferences(IconActions)
