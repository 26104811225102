//@ts-nocheck
import fetch from 'isomorphic-fetch'
import { putOptions, parseJSON } from 'apis/helpers'

const getMeasurementUnit = (val: string): string => {
  if (val.search(/^in/gi) > -1) {
    return 'INCH'
  }

  return 'CENTIMETER'
}

const transformPayload = (payload: Record<string, any>): Record<string, any> => {
  const { country, currency, measurementUnit } = payload
  return {
    preferred_destination_country: country,
    preferred_currency: currency,
    preferred_unit_of_measurement: getMeasurementUnit(measurementUnit),
  }
}

export const updateMyUserPreferences = async (
  data: Record<string, any>
): Promise<Record<string, any>> => {
  const body = transformPayload(data)
  const options = { ...putOptions, body: JSON.stringify(body) }
  const response = await fetch(`/api/user/preferences`, options)
  const json = await parseJSON(response, 'updateMyUserPreferences')
  return json
}
