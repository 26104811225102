import React from 'react'
import styles from './MainTopHeader.module.scss'
import SALogo from 'components/HeaderV2/IconActions/SALogo'
import IconActions from 'components/HeaderV2/IconActions/IconActions'

const MainTopHeader = () => {
  return (
    <>
      <div className={styles.topHeaderMenuIconBox}>
        <SALogo />
      </div>
      <div className={styles.iconsWrapper}>
        <IconActions modalName='user-preferences-header-mobile' />
      </div>
    </>
  )
}

export default MainTopHeader
