import React, { FC } from 'react'
import SALink from 'components/SALink'
import { LinkObject } from '../FooterV3Data'
import { ICONS_MAP } from './Links'

const LinkItem: FC<LinkObject> = ({ title, url, icon }) => {
  const isAnotherDomain = url.includes('http')

  return (
    <SALink
      key={`${title}`}
      href={url}
      data-gtm-event='footer'
      rel={isAnotherDomain ? 'noopener noreferrer' : undefined}
      target={isAnotherDomain ? '_blank' : ''}
      className={'defaultTypographyXSmall'}
    >
      {!!icon && ICONS_MAP[icon]}
      {title}
    </SALink>
  )
}

export default LinkItem
