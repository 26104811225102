import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Content } from './styles'
declare let document
let rootSelector: HTMLElement
let divContainer: HTMLElement

const Component = (props: Record<string, any>): React$ElementRef<any> => {
  const { children, isVisible, type } = props
  const [isReady, setupElements] = useState(false)
  useEffect(() => {
    rootSelector = document.getElementById('cart-quick-view')
    divContainer = document.createElement('div')
    divContainer.setAttribute('class', 'cart-quick-view')

    if (rootSelector && !isReady) {
      // Always Cleanup
      rootSelector.innerHTML = ''
      rootSelector.appendChild(divContainer)
      setupElements(true)
    }

    return () => {
      rootSelector.removeChild(divContainer)
    }
  }, [])

  if (!isReady) {
    return null
  }

  const AnimatedMessage = (
    <Content data-visible={isVisible} type={type}>
      {children}
    </Content>
  )
  return ReactDOM.createPortal(AnimatedMessage, divContainer)
}

export default Component