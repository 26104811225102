import { createSelector } from 'reselect'
import type { User } from 'types/User'
import 'types/User'
import { getUser } from './helpers'
export const getUserAccount = createSelector(
  [getUser],
  (user: User): Record<string, any> => user.account || {}
)
export const getUserAccountIsLoaded = createSelector(
  [getUserAccount],
  (account): boolean => account.hasLoaded || false
)
export const getUserAvatar = createSelector(
  [getUserAccount],
  (account): string =>
    account.avatar || '//d3t95n9c6zzriw.cloudfront.net/common/profile/avatar_medium_square.jpg'
)
export const getIsCheckInOverdue = createSelector([getUserAccount], (account): boolean => {
  const overdue = !!account.isCheckInOverdue
  return overdue
})
export const getIsCheckInDue = createSelector([getUserAccount], (account): boolean => {
  const required = !!account.isCheckInDue
  return required
})
export const getIsOnVacation = createSelector(
  [getUserAccount],
  (account): boolean => account.isOnVacation || false
)
export const getIsVerifiedToSell = createSelector(
  [getUserAccount],
  (account): boolean => account.isVerifiedToSell || false
)
export const getUserAccountAddresses = createSelector(
  [getUserAccount],
  (account): Record<string, any> => account.addresses || []
)
export const getUserAccountAddressesByBookItemId = (addressBookItemId: string) =>
  createSelector(
    [getUserAccountAddresses],
    (addresses): Record<string, any> =>
      addresses.find((address) => address.addressBookItemId === addressBookItemId) || {}
  )