import type { ConnectorProps as ScreenProps } from 'connectors/Screen'
import ScreenConnector from 'connectors/Screen'
import useResizeScreen from 'hooks/resizeScreen'

const Component = ({ isMobile, updateScreenSize }: ScreenProps): null => {
  useResizeScreen({
    callback: updateScreenSize,
    duration: 250,
    isMobile,
  })
  return null
}

export default ScreenConnector(Component)