import React from 'react' // eslint-disable-line

import styled from 'styled-components'
export const Content = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: translate3d(0, -110%, 0);
  will-change: transform, opacity;
  z-index: 1015;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  min-height: 50px;
  color: #333333;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  background: #ffffff;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 10%), 0 15px 15px 0 rgb(0 0 0 / 10%);

  &[data-visible='true'] {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  /* Desktop */
  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    max-width: 350px;
    justify-content: flex-end;
  }

  [data-type='close'] {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #fff;
    height: 25px;
    width: 25px;
    cursor: pointer;
    border: none;
    margin: 0;
    path {
      stroke: #000;
      stroke-width: 3;
    }
  }

  [data-type='message'] {
    margin: 20px auto;
    font-weight: bold;
  }

  [data-type='add-to-cart'] {
    margin: 0 0 20px;
    font-size: 14px;
    display: flex;
    width: 100%;
    svg[data-type='check-icon'] {
      width: 20px;
      height: 20px;
      path {
        fill: #80bc30;
      }
    }
    span {
      margin-left: 10px;
    }
  }

  [data-section='products'] {
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  [data-type='cart-item'] {
    display: flex;
    img {
      width: 50px;
      height: 50px;
    }
    [data-section='info'] {
      display: flex;
      flex-direction: column;
      flex: 1 auto;
      max-width: 240px;
      margin-left: 20px;
      [data-type='title'] {
        font-style: italic;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
        max-width: 90%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      [data-type='price'] {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  [data-section='buttons'] {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    padding: 0 5px 10px;
    button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border: none;
      outline: none;
      background-color: #dddddd;
      color: #ffffff;
      font-weight: bold;
      &[valid],
      &:not([disabled]) {
        background-color: #80bc30;
      }
      &[data-type='shop'] {
        width: auto;
        background: transparent;
        border: none;
        height: 20px;
        text-decoration: underline;
        font-weight: normal;
        font-size: 14px;
        color: #333333;
      }
      &[data-type='checkout'] {
      }
    }
  }
`