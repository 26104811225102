"use strict";
//@ts-nocheck
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.passwordValidator = exports.weekendValidator = exports.dateValidator = exports.latinLetterValidator = exports.letterValidator = exports.nameValidator = exports.notEmptyValidator = exports.emailValidator = exports.validateRulesetAgainstProps = exports.validateRuleset = exports.validateRules = void 0;
exports.equalToValidator = equalToValidator;
exports.equalToValidatorCreator = equalToValidatorCreator;
exports.lengthValidatorCreator = lengthValidatorCreator;
exports.numberRangeValidator = numberRangeValidator;
exports.parseErrors = parseErrors;
/**
 * Validate Array<Validator>
 */
const validateRules = (rules, key, data, options, errors) => __awaiter(void 0, void 0, void 0, function* () {
    return Promise.all(rules.map((validator) => __awaiter(void 0, void 0, void 0, function* () {
        const value = data[key];
        const validationResult = yield validator.validate(value, data);
        if (!validationResult) {
            const error = {
                message: validator.message(key, value, data),
                value: key,
                prop: value,
            };
            if (options && !options.allRules) {
                throw [error];
            }
            errors.push(error);
        }
        return validationResult;
    })));
});
exports.validateRules = validateRules;
/**
 * Validate component props based on a given ruleset.
 */
const validateRuleset = (ruleset, data, options) => __awaiter(void 0, void 0, void 0, function* () {
    const keys = Object.keys(ruleset || {});
    const errors = []; // used w/ `allRules`
    return Promise.all(keys.map((key) => {
        const validators = ruleset[key];
        if (!validators) {
            return Promise.resolve();
        }
        const propValue = data[key];
        return Promise.all(validators.map((validator) => __awaiter(void 0, void 0, void 0, function* () {
            const validationResult = yield validator.validate(propValue, data);
            if (!validationResult) {
                const error = {
                    message: validator.message(key, propValue, data),
                    value: propValue,
                    prop: key,
                };
                if (options && !options.allRules) {
                    throw [error];
                }
                errors.push(error);
            }
            return validationResult;
        })));
    })).then(() => {
        if (options && options.allRules && errors.length) {
            throw errors;
        }
        return Promise.resolve();
    });
});
exports.validateRuleset = validateRuleset;
/**
 * Validate component props based on a given ruleset.
 * Throw an exception upon invalid prop value
 */
const validateRulesetAgainstProps = (ruleset, props, options) => __awaiter(void 0, void 0, void 0, function* () {
    const keys = Object.keys(props);
    const errors = []; // used w/ `allRules`
    return Promise.all(keys.map((key) => {
        const validators = ruleset[key];
        if (!validators) {
            return Promise.resolve();
        }
        const propValue = props[key];
        return Promise.all(validators.map((validator) => __awaiter(void 0, void 0, void 0, function* () {
            const validationResult = yield validator.validate(propValue, props);
            if (!validationResult) {
                const error = {
                    message: validator.message(key, propValue),
                    value: propValue,
                    prop: key,
                };
                if (options && !options.allRules) {
                    throw [error];
                }
                errors.push(error);
            }
            return validationResult;
        })));
    })).then(() => {
        if (options && options.allRules && errors.length) {
            throw errors;
        }
        return Promise.resolve();
    });
});
exports.validateRulesetAgainstProps = validateRulesetAgainstProps;
exports.emailValidator = {
    message: () => 'Invalid email address',
    validate: (value) => {
        // Throw an error if there are `WHITE SPACES` in email
        if (value.search(/\s/) > -1) {
            return false;
        }
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(value);
    },
};
// export const emailAvailableValidator: Validator<string> = {
//   message: () => 'This email is already registered',
//   validate: async (value: string) => {
//     try {
//       const response = await callApi('/auth-api/email-available', {
//         method: 'post',
//         body: JSON.stringify({ email: value }),
//       })
//       return response.available
//     } catch (e) {
//       return true
//     }
//   },
// }
exports.notEmptyValidator = {
    message: (key) => `${key} cannot be empty`,
    validate: (value) => {
        if (Array.isArray(value)) {
            return value.length > 0;
        }
        else if (value === null || value === undefined) {
            return false;
        }
        else if (typeof value === 'string' && !value.replace(/\s/g, '').length) {
            // No empty strings ' '
            return false;
        }
        else if (typeof value === 'object') {
            return Object.keys(value).length > 0;
        }
        return value !== '' && typeof value !== 'undefined';
    },
};
exports.nameValidator = {
    message: () => 'Name contains invalid characters',
    validate: (value) => {
        const regex = /[^a-zA-Z'\-\s]/;
        return !regex.test(value);
    },
};
exports.letterValidator = {
    message: () => 'String should contain at-least one alphabet',
    validate: (value) => {
        const regex = /([a-zA-Z0-9])+([-~])*/;
        return regex.test(value);
    },
};
exports.latinLetterValidator = {
    message: () => 'String should contain only Latin characters',
    validate: (value) => {
        const regex = /^[ A-Za-z_0-9/\\[\]@.:/#,*!|?$%&~`;=^<>{}()+'"-]*$/i;
        return regex.test(value);
    },
};
exports.dateValidator = {
    message: () => 'Date must be greater than or equal to todays date',
    validate: (value) => {
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        const givenDate = new Date(value.replace(/-/g, '/'));
        givenDate.setHours(0, 0, 0, 0);
        return todayDate <= givenDate;
    },
};
// export const dateRangeValidator: Validator<string> = {
//   message: () => 'Date must be within 14 days from today',
//   validate: (value: string) => {
//     const givenDate = new Date(value.replace(/-/g, '/'))
//     givenDate.setHours(0, 0, 0, 0)
//     const fortnightAway = new Date(Date.now() + 12096e5)
//     fortnightAway.setHours(0, 0, 0, 0)
//     return givenDate <= fortnightAway
//   },
// }
// export const threeDaysDateRangeValidator: Validator<string> = {
//   message: () => 'Please select a date 3 days from today or later',
//   validate: (value: string) => {
//     const givenDate = new Date(value.replace(/-/g, '/'))
//     givenDate.setHours(0, 0, 0, 0)
//     const date = new Date()
//     date.setHours(0, 0, 0, 0)
//     date.setDate(date.getDate() + 3)
//     return date <= givenDate
//   },
// }
exports.weekendValidator = {
    message: () => 'Pickup cannot be scheduled on weekends',
    validate: (value) => {
        const givenDate = new Date(value);
        return !(givenDate.getDay() === 6 || givenDate.getDay() === 5);
    },
};
exports.passwordValidator = {
    validate: (value) => {
        const alpha = /[a-z]+/i;
        const nonAlpha = /[^a-z]+/i;
        return alpha.test(value) && nonAlpha.test(value);
    },
    message: () => 'Password should contain a combination of letters and numbers',
};
function equalToValidator(compareValue) {
    return {
        validate: (value, props) => value === compareValue,
        message: (propName) => `${propName} didn't match ${compareValue}`,
    };
}
function equalToValidatorCreator(comparePropName) {
    return {
        validate: (value, props) => value === props[comparePropName],
        message: (propName) => `${propName} didn't match ${comparePropName}`,
    };
}
function lengthValidatorCreator({ min, max, }) {
    return {
        validate: (value) => {
            const { length } = value;
            if (min && max) {
                return length >= min && length <= max;
            }
            else if (min) {
                return length >= min;
            }
            else if (max) {
                return length <= max;
            }
            throw new Error('No min or max specified');
        },
        message: (key) => {
            if (min && max) {
                return `${key} should be between ${min} and ${max} characters long`;
            }
            else if (min) {
                return `${key} should be at least ${min} characters long`;
            }
            else if (max) {
                return `${key} should be no more than ${max} characters long`;
            }
            throw new Error('No min or max specified');
        },
    };
}
function numberRangeValidator({ min, max, }) {
    return {
        validate: (value) => {
            const numberValue = Number(value);
            if (min && max) {
                return numberValue >= min && numberValue <= max;
            }
            else if (min) {
                return numberValue >= min;
            }
            else if (max) {
                return numberValue <= max;
            }
            throw new Error('No min or max specified');
        },
        message: (key) => {
            if (min && max) {
                return `${key} should be between ${min} and ${max}.`;
            }
            else if (min) {
                return `${key} should be at least ${min}.`;
            }
            else if (max) {
                return `${key} should be no more than ${max}.`;
            }
            throw new Error('No min or max specified');
        },
    };
}
function parseErrors(errors) {
    const errorObj = {};
    if (Array.isArray(errors)) {
        errors.forEach((error) => (errorObj[error.prop] = error.message));
    }
    return errorObj;
}
