import type { ValidationRuleset } from 'lib/validation'
import { notEmptyValidator } from 'lib/validation'
export const VALIDATION_RULES: ValidationRuleset = {
  country: [{ ...notEmptyValidator, message: () => 'Country name is required.' }],
  language: [{ ...notEmptyValidator, message: () => 'Language name is required.' }],
  currency: [{ ...notEmptyValidator, message: () => 'Currency address is required.' }],
  measurement: [{ ...notEmptyValidator, message: () => 'Measurement is required.' }],
}
export const preferenceForm = {
  country: 'us',
  language: 'us',
  currency: 'us',
  measurement: 'in',
}
export const preferencesRules: ValidationRuleset = VALIDATION_RULES