import React from 'react'
import { ConnectorProps } from 'connectors/UserPreferences'
import UserPreferences from 'connectors/UserPreferences'
import { ConnectorProps as GeoLocationProps } from 'connectors/GeoLocation'
import GeoLocationConnector from 'connectors/GeoLocation'
import { createNewBrowserPath } from 'components/GeoLocation/helpers'
import { setClientStorage } from 'store/middleware/client'
import { fetchAllClientData } from 'apis/client'
import Modal from 'components/SA/Modal/v2'
import Preferences from '../Forms/Preferences'
import Close from 'svg/close-x.svg'
import styles from './Preferences.module.scss'
import ModalConnector, { ConnectorProps as ModalProps } from 'containers/Modal'

type PreferencesModalProps = ModalProps & ConnectorProps & GeoLocationProps & { modalName: string }

const PreferencesModal = (props: PreferencesModalProps) => {
  const {
    availableCountries,
    closeModal,
    currenciesDropdownData,
    isAuthenticated,
    updateUserPreferences,
    userPreferences,
    modalName,
  } = props

  const handleSubmit = async () => {
    // Fetch Session and Cart data
    const { payload: allClientData, success: wasAllClientDataFetched } = await fetchAllClientData()

    if (!wasAllClientDataFetched) {
      console.error('Problem Fetching Session')
      return
    }

    const { country: userPreferencesCountry } = userPreferences
    setClientStorage(allClientData)
    updateUserPreferences(allClientData) // update user preferences in the DB from session

    const { country: newCountry } = allClientData.cookie.userPreferences
    const shouldRedirect = userPreferencesCountry !== newCountry

    if (shouldRedirect) {
      const newPrefix = `${`en-${newCountry.toLowerCase()}`}`
      const path = createNewBrowserPath(newPrefix)
      window.location = path
    }

    closeModal()
  }

  return (
    <Modal
      name={modalName}
      closeModal={closeModal}
      layout='standard'
      styleOverride={styles.preferencesModal}
    >
      <>
        <Close onClick={closeModal} className={styles.closeIcon} />
        <Preferences
          availableCountries={availableCountries}
          currenciesDropdownData={currenciesDropdownData}
          isAuthenticated={isAuthenticated}
          onSubmit={handleSubmit}
          userPreferences={userPreferences}
        />
      </>
    </Modal>
  )
}

export default ModalConnector(GeoLocationConnector(UserPreferences(PreferencesModal)))
