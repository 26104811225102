import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getLocaleCountryCode } from 'selectors/shared/locale'
import { getUserPreferencesCountry } from 'selectors/user/cookie'
import * as Session from 'selectors/user/session'
import * as Location from 'selectors/shared/location'
const mapStateToProps = createStructuredSelector({
  authenticated: Session.isAuthenticated,
  userInitials: Session.getUserInitials,
  fullName: Session.getFullName,
  isTakeover: Session.getIsTakeover,
  isLimitedArtist: Session.getIsLimitedArtist,
  localizationCountry: getLocaleCountryCode,
  location: Location.getLocation,
  measurementUnit: Session.getMeasurementUnit,
  profileUrl: Session.getProfileUrl,
  selectedCountryCode: getUserPreferencesCountry,
  session: Session.getSession,
  isSessionReady: Session.isSessionSet,
  userId: Session.getUserID,
  userTypeId: Session.getUserTypeId,
})
export type ConnectorProps = {
  authenticated: boolean
  userInitials: string
  fullName: string
  isLimitedArtist: boolean
  isTakeover: boolean
  localizationCountry: string
  location: string
  measurementUnit: string
  profileUrl: string
  selectedCountryCode: string
  session: Record<string, any>
  isSessionReady: boolean
  userId: number
  userTypeId: number
}
export default connect(mapStateToProps, null)
