import React, { FC, ReactNode, useState } from 'react'
import AccordionItem from './AccordionItem'
import styles from './Accordion.module.scss'

export type AccordionItemType = {
  title: string
  content: ReactNode
  headerCustomStyle?: string
}

type AccordionProps = {
  items: ReadonlyArray<AccordionItemType>
  headerCustomStyle?: string
}

const Accordion: FC<AccordionProps> = ({ items, headerCustomStyle }) => {
  const [activeIndex, setActiveIndex] = useState(null)

  const handleChangeIndex = (index) => {
    setActiveIndex(index === activeIndex ? null : index)
  }

  return (
    <div className={styles.accordionContainer}>
      {items.map((item, index) => (
        <AccordionItem
          key={index.toString()}
          title={item.title}
          headerCustomStyle={headerCustomStyle}
          activeIndex={activeIndex}
          onChangeIndex={handleChangeIndex}
        >
          {item.content}
        </AccordionItem>
      ))}
    </div>
  )
}

export default Accordion
