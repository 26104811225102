import React, { FC, useMemo, useState } from 'react'
import MobileHeaderV2 from './MobileHeaderV2/MobileHeaderV2'
import DesktopHeaderV2 from './DesktopHeaderV2/DesktopHeaderV2'
import { HeaderV2Provider, MobileHeaderConfig } from './HeaderV2Context'
import ABTestConnector, { ConnectorProps as ABTestProps } from 'connectors/ABTest'
import { NavigationLinks } from './NavigationLinks'
import { abTestInSubNavLinks, transformNavLinks } from 'components/Header/Navigation/helpers'
import CartUrgencyBar from 'components/CartUrgencyBar'
import styles from './HeaderV2.module.scss'

const HeaderV2: FC<ABTestProps> = ({ abTest }) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
  const [mobileHeaderConfig, setMobileHeaderConfig] = useState<MobileHeaderConfig>({
    mobileTopHeader: 'mainTopHeader',
    mobileMenuContent: 'null',
    isMobileMenuOpen: false,
  })

  const transformedNavigationLinks = useMemo(() => {
    return transformNavLinks({
      navigationLinks: NavigationLinks,
      transformFunction: (navigationLinksParam) =>
        abTestInSubNavLinks({ navigationLinks: navigationLinksParam, abTest }),
    })
  }, [abTest])

  return (
    <HeaderV2Provider
      value={{
        selectedCategory,
        setSelectedCategory,
        mobileHeaderConfig,
        setMobileHeaderConfig,
        navigationLinks: transformedNavigationLinks,
      }}
    >
      <MobileHeaderV2 />
      <DesktopHeaderV2 />
      <div className={styles.cartUrgencyBox}>
        <CartUrgencyBar />
      </div>
    </HeaderV2Provider>
  )
}

export default ABTestConnector(HeaderV2)
