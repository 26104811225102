"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleRefFocus = exports.handleScreenReaderEnter = exports.handleScreenReader = void 0;
/*
  Move to a GLOBAL helper - Used for `onKeyDown` | KEY PRESS events for screen readers
  Event Keys we want to Take Actions On: 9 => TAB, 13 => ENTER, 32 => SPACE

  ```javascript
    <div onClick={someAction} onKeyPress={(ev) => handleScreenReader(ev) && someAction(ev)} role='button' tabIndex='0' />
  ```
*/
const handleScreenReader = (event) => {
    // FireFox -> keyCode property does not work on the onkeypress event (will only return 0)
    const code = String(event.which || event.keyCode);
    // console.log({ key: event.keyCode })
    if (code.match(/^(9|13|32)$/)) {
        return true;
    }
    return false;
};
exports.handleScreenReader = handleScreenReader;
const handleScreenReaderEnter = (event) => {
    // FireFox -> keyCode property does not work on the onkeypress event (will only return 0)
    const code = String(event.which || event.keyCode);
    // console.log({ key: event.keyCode })
    if (code.match(/^(13)$/)) {
        return true;
    }
    return false;
};
exports.handleScreenReaderEnter = handleScreenReaderEnter;
const handleRefFocus = ({ ref, callback }) => {
    // This is necessary because we need to first check if
    // another child of the element has received focus
    // by adding a DELAY for events to BUBBLE/OCCUR
    setTimeout(() => {
        const isActive = ref.current.contains(document.activeElement);
        if (isActive) {
            callback();
        }
    });
};
exports.handleRefFocus = handleRefFocus;
