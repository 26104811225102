import React, { FC, useEffect, useRef, useState } from 'react'
import AccountIcon from 'svg/user-login.svg'
import styles from './Account.module.scss'
import MobileConnector, { ConnectorProps as MobileConnectorProps } from 'connectors/Screen'
import { useHeaderV2Context } from 'components/HeaderV2/HeaderV2Context'
import AuthLinksDropDown from './AuthLinksDropDown'
import classNames from 'classnames'

const Authentication: FC<MobileConnectorProps> = ({ isMobileScreenWidth }) => {
  const { setMobileHeaderConfig } = useHeaderV2Context()
  const [dropdownIsOpen, handleDropdownOpen] = useState(false)
  const [dropdownIsHovered, setDropdownIsHovered] = useState(false)
  const authRef = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    handleDropdownOpen((prevState) => !prevState)
    setDropdownIsHovered(false)
    if (isMobileScreenWidth) {
      setMobileHeaderConfig((previousMobileHeaderConfig) => ({
        ...previousMobileHeaderConfig,
        mobileMenuContent: 'auth',
        mobileTopHeader: 'user',
        isMobileMenuOpen: true,
      }))
    }
  }

  const handleDropdownHovered = (booleanFlag) => {
    return () => {
      if (!isMobileScreenWidth) {
        setDropdownIsHovered(booleanFlag)
      }
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (authRef.current && !authRef.current.contains(event.target)) {
        handleDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div
      className={styles.accountDropDownMenu}
      onMouseEnter={handleDropdownHovered(true)}
      onMouseLeave={handleDropdownHovered(false)}
      onFocus={handleDropdownHovered(true)}
      onBlur={handleDropdownHovered(false)}
      data-type='auth-icon'
      ref={authRef}
    >
      <button
        type='button'
        className={classNames(styles.accountAuthIcon)}
        aria-label='authentication links'
        onClick={handleClick}
        data-type={`auth-icon${isMobileScreenWidth ? '-mobile' : ''}`}
      >
        <AccountIcon />
        <div
          data-type={`account-plus-icon${isMobileScreenWidth ? '-mobile' : ''}`}
          className={styles.accountPlusIcon}
        >
          +
        </div>
      </button>
      <AuthLinksDropDown dropdownIsOpen={dropdownIsOpen} dropdownIsHovered={dropdownIsHovered} />
    </div>
  )
}

export default MobileConnector(Authentication)
