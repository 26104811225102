import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import dynamic from 'next/dynamic'
import noSSR from 'components/DynamicLoader'
import { Props, DEFAULT_STYLES, ANIMATION } from './helpers'
import styles from './FlashMessage.module.scss'

const Animation = (
  props: Record<string, any>
): React.ReactElement<React.ComponentProps<any>, any> => {
  const AnimationComponent = dynamic(
    import(
      /* webpackChunkName: "flashAnimation" */
      'components/Animations/Animation'
    ),
    noSSR
  )
  return (
    <AnimationComponent defaultStyles={DEFAULT_STYLES} duration={250} {...ANIMATION} {...props} />
  )
}

declare let document
let rootSelector: HTMLElement
let divContainer: HTMLElement

const Component = (props: Props): React$ElementRef<any> => {
  const { children, closeFlash, isVisible, type } = props
  const [isReady, setupElements] = useState(false)
  useEffect(() => {
    rootSelector = document.getElementById('flash')
    divContainer = document.createElement('div')
    divContainer.setAttribute('class', 'flash')

    if (rootSelector && !isReady) {
      // Always Cleanup
      rootSelector.innerHTML = ''
      rootSelector.appendChild(divContainer)
      setupElements(true)
    }

    return () => {
      rootSelector.removeChild(divContainer)
    }
  }, [])

  if (!isReady) {
    return null
  }

  const AnimatedMessage = (
    <Animation isVisible={isVisible}>
      <div className={styles.flashContainer} onClick={closeFlash}>
        {children}
      </div>
    </Animation>
  )
  return ReactDOM.createPortal(AnimatedMessage, divContainer)
}

export default Component
