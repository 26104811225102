import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
type Props = {
  children: React$ElementRef<any>
  closeModal: (...args: Array<any>) => any
}
declare let document
let rootSelector: HTMLElement
let divContainer: HTMLElement
export default (props: Props): React$ElementRef<any> => {
  const { children, closeModal } = props

  const keyPress = (ev: KeyboardEvent) => {
    if (ev.keyCode === 27) {
      closeModal()
    }
  }

  const [isReady, setupElements] = useState(false)
  useEffect(() => {
    rootSelector = document.getElementById('modal')
    divContainer = document.createElement('div')
    divContainer.setAttribute('class', 'modal')

    if (rootSelector) {
      // Always Cleanup
      rootSelector.innerHTML = ''
      rootSelector.appendChild(divContainer)
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
      document.addEventListener('keydown', keyPress)
      setupElements(true)
    }

    return () => {
      try {
        rootSelector.removeChild(divContainer)
        document.getElementsByTagName('body')[0].style.overflow = 'auto'
        document.removeEventListener('keydown', keyPress)
      } catch (error) {
        console.error(error)
      }
    }
  }, [])

  if (!isReady) {
    return null
  }

  return ReactDOM.createPortal(<>{children}</>, divContainer)
}
