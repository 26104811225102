import React from 'react'
import type { ConnectorProps as SessionProps } from 'containers/Session'
import SessionConnect from 'containers/Session'
import { AuthenticationWrapper } from './styles'
import UserInfo from './UserInfo'
type Props = SessionProps
const Authentication = SessionConnect((props: Props): React.ReactElement<
  React.ComponentProps<any>,
  any
> | null => {
  const { isSessionReady } = props

  if (!isSessionReady) {
    return null
  }

  return (
    <AuthenticationWrapper data-section='authentication'>
      <UserInfo />
    </AuthenticationWrapper>
  )
})
export default Authentication