import React from 'react'
import { submitSearch } from 'components/Header/Navigation/Search/helpers'
import SearchInput from './SearchInput'
import CloseIcon from 'svg/close.svg'
import styles from './SearchInput.module.scss'
import { useHeaderV2Context } from '../HeaderV2Context'
import classNames from 'classnames'

const SearchTopHeader = () => {
  const { mobileHeaderConfig, setMobileHeaderConfig } = useHeaderV2Context()

  const handleSearch = (query: string) => {
    submitSearch({
      query,
      type: 'art',
    })
  }

  const handleClose = () => {
    setMobileHeaderConfig((previousMobileHeaderConfig) => ({
      ...previousMobileHeaderConfig,
      mobileTopHeader: 'mainTopHeader',
      mobileMenuContent: 'null',
      isMobileMenuOpen: false,
    }))
  }

  return (
    <div
      className={classNames(styles.searchHeaderContainer, {
        [styles.searchHeaderContainerOpened]: mobileHeaderConfig.isMobileMenuOpen,
      })}
    >
      <SearchInput onSearch={handleSearch} />
      <div className={styles.searchHeaderCloseIconBox} onClick={handleClose}>
        <CloseIcon />
      </div>
    </div>
  )
}

export default SearchTopHeader
