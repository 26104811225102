import React from 'react'
import CheckCircleIcon from 'svg/check-circle.svg'
import { addStoreLocaleByCookie } from 'components/GeoLocation/helpers'
import Item from './Item'
type Props = {
  cart: Record<string, any>
  closeCart: (...args: Array<any>) => any
  productSku: string | null | undefined
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { cart, closeCart, productSku } = props
  const { items } = cart

  if (!items?.length) {
    return <p data-type='message'>Oops, your cart is empty.</p>
  }

  if (productSku) {
    const product = items.find(({ sku }) => sku === productSku) || {}
    const { sku } = product

    if (!sku) {
      return <p data-type='message'>Sorry, unable to locate product.</p>
    }

    return (
      <>
        <p data-type='add-to-cart'>
          <CheckCircleIcon data-type='check-icon' />
          <span>Added to Cart</span>
        </p>

        <div data-section='products'>
          <Item data-type='item' product={product} />
        </div>

        <div data-section='buttons'>
          <button data-type='shop' type='button' onClick={closeCart}>
            Continue Shopping
          </button>

          <button
            data-type='checkout'
            type='button'
            onClick={() => {
              const checkoutLocation = addStoreLocaleByCookie(`/checkout`)
              location.assign(checkoutLocation)
            }}
          >
            Checkout
          </button>
        </div>
      </>
    )
  }

  return (
    <>
      <div data-section='products'>
        {items.map((product: Record<string, any>) => {
          const { sku } = product
          return <Item data-type='item' key={`cart-summary-item-${sku}`} product={product} />
        })}
      </div>

      <div data-section='buttons'>
        <button data-type='shop' type='button' onClick={closeCart}>
          Continue Shopping
        </button>

        <button
          data-type='checkout'
          type='button'
          onClick={() => {
            const checkoutLocation = addStoreLocaleByCookie(`/checkout`)
            location.assign(checkoutLocation)
          }}
        >
          Checkout
        </button>
      </div>
    </>
  )
}

export default Component