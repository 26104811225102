import React, { useEffect } from 'react'
import styles from './ModalV2.module.scss'
import Portal from './Portal'
import ModalConnector, { ConnectorProps as ModalProps } from 'containers/Modal'
import Animation from 'components/Animations/Animation'
import classNames from 'classnames'
let timer

const timerCleanup = () => {
  clearTimeout(timer)
  timer = null
}

const animationDelay = (callBack: (...args: Array<any>) => any, duration) => {
  timerCleanup()
  timer = setTimeout(() => {
    callBack() // We remove ALL Redux state modal info
  }, duration)
}
const animationDuration = 150
const animation = {
  from: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
  },
}
const defaultAnimationStyles = {
  position: 'fixed',
  top: '0',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  willChange: 'transform, opacity',
  zIndex: '1000',
  overflow: 'auto',
}

type Props = ModalProps & {
  children: React.ReactElement<React.ComponentProps<any>, any>
  layout: string
  styleOverride?: string
  decayTime?: number
  fullTakeover?: boolean
  //closeModal: (...args: Array<any>) => any
}
const Modal = (props: Props): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const {
    children,
    closeModal,
    layout,
    styleOverride,
    isModalAvailable,
    resetModal,
    isVisible,
    decayTime,
    fullTakeover,
  } = props

  // We check Redux state to see if NAME matches currrent modal
  if (!isModalAvailable) {
    return null
  }
  const duration = decayTime || 500
  // Wait for animations to finish, before we clear/remove modal
  useEffect(() => {
    if (!isVisible) {
      animationDelay(resetModal, duration)
    }
  }, [isVisible])
  return (
    <Portal closeModal={closeModal}>
      <Animation
        defaultStyles={defaultAnimationStyles}
        duration={animationDuration}
        isVisible={isVisible}
        {...animation}
      >
        <div className={`${styles.modal} ${styleOverride}`} data-type='modal'>
          {layout === 'standard' && <div className={styles.modalBackground} onClick={closeModal} />}
          <div
            className={classNames(
              {
                [styles.modalContentFullTakeover]: fullTakeover,
              },
              styles.modalContent
            )}
            data-type='modal-content'
          >
            <aside role='dialog' aria-modal='true' tabIndex={-1}>
              {children}
            </aside>
          </div>
        </div>
      </Animation>
    </Portal>
  )
}
export default ModalConnector(Modal)
