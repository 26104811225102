/*
We want a single way to control and dispatch
Window Resize ( via `Screen` namespace ) events to redux store
We want to add this to the APP and have SELECTORS/CONTAINERS
to dispatch Events to individual components
*/
import { useEffect, useState } from 'react'
import debounce from 'lib/debounce'
const resizeDebounce = debounce()
const orientationDebounce = debounce()
const PORTRAIT = 'portrait'
const LANDSCAPE = 'landscape'
type Props = {
  callback?: (...args: Array<any>) => any
  delay?: number
  isMobile?: boolean
}
export default ({ callback, delay, isMobile }: Props): Record<string, any> => {
  const [screenSize, setScreen] = useState({})
  const [isReady, setIsReady] = useState(false)

  const checkOrientation = (): string => {
    return window.innerWidth < window.innerHeight ? PORTRAIT : LANDSCAPE
  }

  const handleOrientation = () => {
    if (isMobile) {
      const data = {
        height: window.innerHeight,
        width: window.innerWidth,
        orientation: checkOrientation(),
      }
      setScreen(data)

      if (callback) {
        callback(data)
      }
    }
  }

  const handleResize = () => {
    if (typeof window !== 'undefined') {
      const data = {
        height: window.innerHeight,
        width: window.innerWidth,
        orientation: checkOrientation(),
      }

      // We don't resize on Mobile Device - We fire `handleResize` for Mobile on init of useEffect
      // We will listen for ORIENTATION changes ( so ONLY check width )
      if (isMobile && data.width === screenSize.width) {
        // console.log('mobile resize')
        return
      }

      setScreen(data)

      if (callback) {
        callback(data)
      }
    }
  }

  // Desktop Browsers lets space out Events Firing
  const debouncedHandleResize = () => {
    resizeDebounce(handleResize, delay || 500)
  }

  const debouncedHandleOrientation = () => {
    orientationDebounce(handleOrientation, delay || 500)
  }

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize)
    window.addEventListener('orientationchange', debouncedHandleOrientation)
    handleResize()
    setIsReady(true)
    // Cleanup
    return () => {
      setIsReady(false)
      window.removeEventListener('resize', debouncedHandleResize)
      window.addEventListener('orientationchange', debouncedHandleOrientation)
    }
  }, [isMobile])
  // <-- empty array: effect runs only on mount, cleans up on unmount
  return {
    isReady,
    screenSize,
  }
}