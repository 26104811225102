"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lockScrollClass = void 0;
exports.lockScroll = lockScroll;
exports.unlockScroll = unlockScroll;
exports.lockScrollBrowse = lockScrollBrowse;
exports.unlockScrollBrowse = unlockScrollBrowse;
exports.lockScrollClass = 'body-lock-scroll';
let scrollPosition = 0;
let bodyEl;
function getBody() {
    if (bodyEl) {
        return bodyEl;
    }
    bodyEl = document.querySelector('body');
    return bodyEl;
}
function lockScroll() {
    const body = getBody();
    if (!body) {
        return;
    }
    body.classList.add(exports.lockScrollClass);
}
function unlockScroll() {
    const body = getBody();
    if (!body) {
        return;
    }
    body.classList.remove(exports.lockScrollClass);
}
// lock/unlock scroll functions for browse app
function lockScrollBrowse() {
    const body = getBody();
    if (!body) {
        return;
    }
    scrollPosition = window.pageYOffset;
    body.style.overflow = 'hidden';
}
function unlockScrollBrowse() {
    const body = getBody();
    if (!body) {
        return;
    }
    body.style.removeProperty('overflow');
    window.scrollTo(0, scrollPosition);
}
