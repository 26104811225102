import { createSelector } from 'reselect'
import { getPageData, getPageParams } from 'selectors/page'
export { getPageData, getPageParams }
export const getArtworkId = createSelector(
  [getPageParams],
  (params): string => params.artworkId || ''
)
export const getSection = createSelector([getPageParams], ({ section }): string => {
  // Default to image on UPLOAD
  if (section === 'upload') {
    return 'image'
  }

  return section || 'dashboard'
})
export const getSubSection = createSelector([getPageParams], (params): string => params.sub || '')
export const getSearch = createSelector([getPageParams], (params): string => params.search || '')
export const getHitsPerPage = createSelector(
  [getPageParams],
  (params): string => params.hitsPerPage || ''
)
export const getPage = createSelector([getPageParams], (params): string => params.page || '')