import React, { FC } from 'react'
import { FOOTER_LINKS, STUDIO_FOOTER_LINKS } from '../FooterV3Data'
import SALink from 'components/SALink'
import styles from './Links.module.scss'
import classNames from 'classnames'
import GiftCardIcon from 'svg/gift-card-icon.svg'

export const ICONS_MAP = {
  ['gift-card']: <GiftCardIcon fill='#ffffff' />,
}

type FooterLinksProps = {
  isStudio?: boolean
}

const FooterLinks: FC<FooterLinksProps> = ({ isStudio }) => {
  const links = isStudio ? STUDIO_FOOTER_LINKS : FOOTER_LINKS

  return (
    <div className={styles.linksContainer} data-type='links-container'>
      {links.map(({ title, links }) => (
        <div className={styles.linksBox} key={title}>
          <span className='defaultTypographyH6'>{title}</span>
          {links.map(({ title: linkTitle, url, icon }) => {
            const isAnotherDomain = url.includes('http')

            return (
              <SALink
                key={`${title}-${linkTitle}`}
                href={url}
                data-gtm-event='footer'
                rel={isAnotherDomain ? 'noopener noreferrer' : undefined}
                target={isAnotherDomain ? '_blank' : ''}
                className={classNames('defaultTypographyXSmall')}
              >
                {!!icon && ICONS_MAP[icon]}
                {linkTitle}
              </SALink>
            )
          })}
        </div>
      ))}
    </div>
  )
}

export default FooterLinks
