/*
  We need to create and update the Cart Storage when ever the user adds/deletes
  items from the cart. The only way possible for this to occur is when a user goes
  to CART/CHECKOUT.

  We also need to check/create this if a USER logs in

  Artworks can sell behind the scenes and the cart could be cleaned up server side.
*/

import { get as getStorage, set as setStorage } from 'lib/localStorage'
import { fetchCart } from 'apis/cart'
import { type CartResponse } from 'types/User/Cart'

export type CartStorageData =
  | {
      cartCount: number,
      cartItemPriceAverage: number,
      categories: Array<string>,
      futureExpirationUnixTimestamp: number,
    }
  | Record<string, any>

const STORAGE_KEY = 'saatchi_cart_data'
const DIGIOH_CART_COUNT = 'sa_cart_count'
const DIGIOH_CART_PRICE_AVERAGE = 'sa_cart_price_avg'
const DIGIOH_CART_CATEGORIES = 'sa_cart_categories'
const EXPIRATION = 1000 * 60 * 60 * 15 // ms * s * m * h => 15 hours


const createDigiohStorage = (data: CartStorageData = {}): void => {
  const { cartCategories, cartCount, cartItemPriceAverage } = data
  setStorage(DIGIOH_CART_COUNT, cartCount || 0)
  setStorage(DIGIOH_CART_PRICE_AVERAGE, parseInt(cartItemPriceAverage / 100, 10) || 0)
  setStorage(DIGIOH_CART_CATEGORIES, cartCategories.join() || '')
}

const killDigiohStorage = (): void => {
  localStorage.removeItem(DIGIOH_CART_COUNT)
  localStorage.removeItem(DIGIOH_CART_PRICE_AVERAGE)
  localStorage.removeItem(DIGIOH_CART_CATEGORIES)
}

export const getCartStorage = (): CartStorageData => getStorage(STORAGE_KEY) || {}

export const setCartStorage = (data: CartStorageData = {}): void => {
  const cartData = getCartStorage(STORAGE_KEY)
  const updatedData = {
    ...cartData,
    ...data,
  }
  setStorage(STORAGE_KEY, updatedData)
  createDigiohStorage(updatedData)
}

export const deleteCartStorage = (): void => {
  localStorage.removeItem(STORAGE_KEY)
  killDigiohStorage()
}

const parseCartData = (cart: CartResponse): void => {
  // Cart items count, categories, price average
  const { itemsCartCount, items, totalOrderPriceToPay } = cart
  const hasOriginals = items.findIndex(({ isOriginal }) => isOriginal) > -1
  const categories = items.reduce((results, { productCategory }) => {
    return [...results, (productCategory || '').toLowerCase()]
  }, [])
  const cartItemPriceAverage = parseInt(totalOrderPriceToPay / itemsCartCount, 10)

  return {
    cartCount: itemsCartCount,
    cartCategories: Array.from(new Set(categories)) || [], // remove duplicates
    cartItemPriceAverage,
    hasOriginals,
  }
}

export const setCartData = (cart: CartResponse): Promise<void> => {
  const { futureExpirationUnixTimestamp: storageStamp } = getStorage(STORAGE_KEY) || {}
  // If exist get timestamp or lets crate a FUTURE timestamp
  const futureExpirationUnixTimestamp = storageStamp || new Date().getTime() + EXPIRATION
  const cartData = parseCartData(cart)
  if (cartData.hasOriginals) {
    setCartStorage({ ...cartData, futureExpirationUnixTimestamp })
  } else {
    deleteCartStorage()
  }
}

export const getCartData = async (): Promise<void> => {
  const { payload, success } = await fetchCart()
  if (success) {
    setCartData(payload)
  }
}

export const showNotification = (): boolean => {
  const { futureExpirationUnixTimestamp, hasOriginals } = getStorage(STORAGE_KEY) || {}
  // User does NOT have any cartData or a timestamp
  if (!futureExpirationUnixTimestamp || !hasOriginals) {
    return false
  }
  const now = new Date().getTime()
  // The time to show urgency has NOT occurred
  const show = futureExpirationUnixTimestamp < now
  return show
}

export const resetNotification = (): void => {
  const futureExpirationUnixTimestamp = new Date().getTime() + EXPIRATION
  setCartStorage({ futureExpirationUnixTimestamp })
}
