import { useCreateContext } from 'hooks/useCreateContext'
import { NavigationLinksCategories } from './NavigationLinks'

type MobileTopHeader = 'mainTopHeader' | 'user' | 'search' | 'account'

type MobileMenuContent = 'categories' | 'subCategories' | 'user' | 'auth' | 'search' | 'null'

export type MobileHeaderConfig = {
  mobileTopHeader: MobileTopHeader
  mobileMenuContent: MobileMenuContent
  isMobileMenuOpen: boolean
}

export const [HeaderV2Provider, useHeaderV2Context] = useCreateContext<{
  readonly setSelectedCategory: (newCategory: string | null) => void
  readonly selectedCategory: string | null
  readonly mobileHeaderConfig: MobileHeaderConfig
  readonly setMobileHeaderConfig: React.Dispatch<React.SetStateAction<MobileHeaderConfig>>
  readonly navigationLinks: ReadonlyArray<NavigationLinksCategories>
}>({
  name: 'HeaderV2Context',
  errorMessage: 'This context should be used inside HeaderV2Provider!',
})
