export type CopyrightLinksObject = {
  title: string
  link: string
}

export type LinkObject = {
  title: string
  url: string
  icon?: 'gift-card'
}

export type FooterLinksObject = {
  title: string
  links: ReadonlyArray<LinkObject>
}

export type TopCategoriesObject = {
  title: string
  categories: ReadonlyArray<LinkObject>
}

export const COPYRIGHTS_LINKS: ReadonlyArray<CopyrightLinksObject> = [
  {
    title: 'Terms of Service',
    link: '/terms',
  },
  {
    title: 'Privacy Notice',
    link: '/privacy',
  },
  {
    title: 'Cookie Notice',
    link: '/cookie_notice',
  },
  {
    title: 'Copyright Policy',
    link: '/copyright',
  },
  {
    title: 'California Notice of Collection',
    link: '/privacy#12.2',
  },
  {
    title: 'Do Not Sell or Share My Personal Information',
    link: '#userPreferences',
  },
  {
    title: 'Accessibility',
    link: '/accessibility',
  },
]

export const FOOTER_LINKS: ReadonlyArray<FooterLinksObject> = [
  {
    title: 'For Collectors',
    links: [
      {
        title: 'Art Advisory',
        url: '/artadvisory',
      },
      {
        title: 'Help Center',
        url: 'https://support.saatchiart.com/hc/en-us',
      },
      {
        title: 'Returns',
        url: 'https://support.saatchiart.com/hc/en-us/articles/14673570463643',
      },
      {
        title: 'Commissions',
        url: '/commissions',
      },
      {
        title: 'Curated Collections',
        url: '/collections',
      },
      {
        title: 'How to Buy Art',
        url: '/stories/how-to-find-the-perfect-artwork',
      },
      {
        icon: 'gift-card',
        title: 'Gift Card',
        url: '/giftcard',
      },
    ],
  },
  {
    title: 'For The Trade',
    links: [
      {
        title: 'About',
        url: '/trade',
      },
      {
        title: 'Trade Program',
        url: '/trade/trade-program',
      },
      {
        title: 'Hospitality',
        url: '/trade/hospitality',
      },
      {
        title: 'Commercial',
        url: '/trade/commercial',
      },
      {
        title: 'Healthcare',
        url: '/trade/healthcare',
      },
      {
        title: 'Multi Family & Residential',
        url: '/trade/residential',
      },
      {
        title: 'Contact Art Consultant',
        url: '/trade-hospitality#contact',
      },
    ],
  },
  {
    title: 'Saatchi Art',
    links: [
      {
        title: 'About',
        url: '/about-us',
      },
      {
        title: 'Saatchi Art Stories',
        url: '/stories',
      },
      {
        title: 'The Other Art Fair',
        url: '/theotherartfair',
      },
      {
        title: 'Sell on Saatchi Art',
        url: '/whysell',
      },
      {
        title: 'Affiliate Program',
        url: 'https://signup.cj.com/member/brandedPublisherSignUp.do?air_refmerchantid=4023958',
      },
      {
        title: 'Careers',
        url: 'https://www.linkedin.com/company/saatchiart/jobs/',
      },
      {
        title: 'Contact Support ',
        url: 'https://support.saatchiart.com/hc/en-us/requests/new',
      },
    ],
  },
]

export const STUDIO_FOOTER_LINKS: ReadonlyArray<FooterLinksObject> = [
  {
    title: 'Support',
    links: [
      {
        title: 'Help Center',
        url: 'https://support.saatchiart.com/hc',
      },
      {
        title: 'Get Started',
        url: 'https://support.saatchiart.com/hc/sections/14062766571419',
      },
      {
        title: 'Payments',
        url: 'https://support.saatchiart.com/hc/sections/14563986089883',
      },
      {
        title: 'Contact Us',
        url: 'https://support.saatchiart.com/hc/requests/new',
      },
    ],
  },
  {
    title: 'Resources',
    links: [
      {
        title: 'Artist Handbook',
        url: 'https://support.saatchiart.com/hc/categories/14062686015643',
      },
      {
        title: 'Packaging Guide',
        url: 'https://support.saatchiart.com/hc/articles/14709932602139',
      },
      {
        title: 'How to Photograph',
        url: 'https://support.saatchiart.com/hc/articles/17737130295963',
      },
      {
        title: 'Artist Tips',
        url: 'https://canvas.saatchiart.com/category/art/artist-tips',
      },
    ],
  },
  {
    title: 'Saatchi Art',
    links: [
      {
        title: 'About',
        url: '/about-us',
      },
      {
        title: 'Sell on Saatchi Art',
        url: '/whysell',
      },
      {
        title: 'Affiliate Program',
        url: 'https://signup.cj.com/member/brandedPublisherSignUp.do?air_refmerchantid=4023958',
      },
    ],
  },
]

export const TOP_CATEGORIES: ReadonlyArray<TopCategoriesObject> = [
  {
    title: 'TOP CATEGORIES',
    categories: [
      {
        title: 'Paintings',
        url: '/paintings',
      },
      {
        title: 'Photography',
        url: '/photography',
      },
      {
        title: 'Sculpture',
        url: '/sculpture',
      },
      {
        title: 'Drawings',
        url: '/drawings',
      },
      {
        title: 'Mixed Media',
        url: '/mixed-media',
      },
      {
        title: 'Fine Art Prints',
        url: '/prints',
      },
    ],
  },
]
