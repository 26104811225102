import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getUserID, isAuthenticated } from 'selectors/user/session'
const mapStateToProps = createStructuredSelector({
  isAuthenticated,
  userId: getUserID,
})
export type ConnectorProps = {
  isAuthenticated: boolean
  userId: number
}
export default connect(mapStateToProps)