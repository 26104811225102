import React, { useEffect, useRef, useState } from 'react'
import SessionConnector, { ConnectorProps as SessionProps } from 'containers/Session'
import { useHeaderV2Context } from 'components/HeaderV2/HeaderV2Context'
import AccountLinksDropDown from './AccountLinksDropDown'
import MobileConnector, { ConnectorProps as MobileConnectorProps } from 'connectors/Screen'
import styles from './Account.module.scss'
import classNames from 'classnames'

type AccountProps = SessionProps & MobileConnectorProps

const Account = (props: AccountProps) => {
  const { userInitials, isMobileScreenWidth } = props
  const { setMobileHeaderConfig } = useHeaderV2Context()
  const [dropdownIsOpen, handleDropdownOpen] = useState(false)
  const [dropdownIsHovered, setDropdownIsHovered] = useState(false)
  const accountRef = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    handleDropdownOpen((prevState) => !prevState)
    setDropdownIsHovered(false)
    if (isMobileScreenWidth) {
      setMobileHeaderConfig((previousMobileHeaderConfig) => ({
        ...previousMobileHeaderConfig,
        mobileMenuContent: 'user',
        mobileTopHeader: 'user',
        isMobileMenuOpen: true,
      }))
    }
  }

  const handleDropdownHovered = (booleanFlag) => {
    return () => {
      if (!isMobileScreenWidth) {
        setDropdownIsHovered(booleanFlag)
      }
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (accountRef.current && !accountRef.current.contains(event.target)) {
        handleDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div
      className={styles.accountDropDownMenu}
      onMouseOver={handleDropdownHovered(true)}
      onMouseLeave={handleDropdownHovered(false)}
      onFocus={handleDropdownHovered(true)}
      onBlur={handleDropdownHovered(false)}
      data-type='account-icon'
      ref={accountRef}
    >
      <button
        type='button'
        aria-label='authentication links'
        className={classNames(styles.accountIcon, {
          [styles.accountIconMobile]: isMobileScreenWidth,
        })}
        onClick={handleClick}
        data-type={`account-initials${isMobileScreenWidth ? '-mobile' : ''}`}
      >
        <span className={styles.accountIconInitials}>{userInitials}</span>
      </button>
      <AccountLinksDropDown dropdownIsOpen={dropdownIsOpen} dropdownIsHovered={dropdownIsHovered} />
    </div>
  )
}

export default SessionConnector(MobileConnector(Account))
