"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* NON FLOW Helpers used Clientside & Serverside */
const { slugifyTitle } = require('./slugify');
const COMMISSION_RATE = 0.6; // Palette should return VALUE
const LIMITED_COMMISSION_RATE = 0.5;
const getProductCategoryUrl = (category) => {
    switch (category) {
        case 'Drawings':
            return 'Drawing';
        case 'Paintings':
            return 'Painting';
        default:
            return category;
    }
};
const getProductSlugifyTitle = ({ category, title }) => {
    return slugifyTitle(`${getProductCategoryUrl(category)}-${title}`);
};
const getProductUrls = ({ legacyUserArtId, slug, userId }) => {
    const path = `${slug}/${userId}/${legacyUserArtId}`;
    const originalProductUrl = `/art/${path}/view`;
    const printProductUrl = `/print/${path}/view`;
    return {
        originalProductUrl,
        printProductUrl,
    };
};
const generateSku = ({ configurationId, userId, artworkId, productTypeId }) => `P${configurationId || 1}-U${userId}-A${artworkId}-T${productTypeId}`;
const parseSku = (sku) => {
    if (!sku) {
        return {};
    }
    const [configurationId, userId, artworkId, productTypeId] = sku
        .split(/-/)
        .map((item) => item.slice(1));
    return {
        configurationId,
        userId,
        artworkId,
        productTypeId,
    };
};
module.exports = {
    COMMISSION_RATE,
    generateSku,
    getProductSlugifyTitle,
    getProductUrls,
    LIMITED_COMMISSION_RATE,
    parseSku,
};
