import React, { FC, useEffect } from 'react'
import UserPreferences, { ConnectorProps as UserPreferencesProps } from 'connectors/UserPreferences'
import styles from './Preferences.module.scss'
import PreferencesModal from 'components/Preferences/Modals/Preferences'
import { updateMyUserPreferences } from 'apis/account/userPreferences'
import ArrowRightIcon from 'svg/thicker-arrow.svg'
import { setUserPreferencesCookie } from 'components/Preferences/apis'
import { fetchAllClientData } from 'apis/client'
import CountryFlagSvg from 'components/CountryFlag/CountryFlagSvg'
import ModalConnector, { ConnectorProps as ModalProps } from 'containers/Modal'

type PreferencesProps = UserPreferencesProps & ModalProps

const MODAL_NAME = 'user-preferences-footer'

const PreferencesFooter: FC<PreferencesProps> = (props) => {
  const {
    availableCountries,
    localeIsReady,
    userPreferences,
    selectedCurrency,
    isAuthenticated,
    userPreferencesMeasurementUnit,
    updateUserPreferences,
    availableCurrencies,
    openModal,
  } = props
  const { country, currency } = userPreferences
  const { currencyCode } = selectedCurrency
  const countryName = availableCountries ? availableCountries[country] : ''
  const currencyName = availableCurrencies[currency]

  // the below code is to support users who have unsupported countries/currencies already selected
  // reset the session and cookie values to US/USD
  // We will delete this code in a year
  const updateAllClientData = async () => {
    // Fetch Session and Cart data
    const { payload, success } = await fetchAllClientData()

    if (!success) {
      console.error('Problem Fetching Session')
      return
    }

    updateUserPreferences(payload)
  }

  const setDefaultCountryPreferences = async () => {
    await updateMyUserPreferences({
      country: 'US',
      currency: currencyCode,
      measurementUnit: userPreferencesMeasurementUnit,
    })
    const { payload } = await setUserPreferencesCookie({
      country: 'US',
      language: 'us',
      currency: currencyCode,
      measurementUnit: userPreferencesMeasurementUnit,
    })
    updateAllClientData(payload)
  }

  const setDefaultCurrencyPreferences = async () => {
    await updateMyUserPreferences({
      country,
      currency: 'USD',
      measurementUnit: userPreferencesMeasurementUnit,
    })
    const { payload } = await setUserPreferencesCookie({
      country,
      language: 'us',
      currency: 'USD',
      measurementUnit: userPreferencesMeasurementUnit,
    })
    updateAllClientData(payload)
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (!countryName) {
        setDefaultCountryPreferences()
      }

      if (!currencyName) {
        setDefaultCurrencyPreferences()
      }
    }
  }, [isAuthenticated])

  // Hide currency selector for specific pages
  if (!localeIsReady || !userPreferences) {
    return null
  }

  return (
    <>
      <div
        title='View Store Preferences Footer'
        className={styles.userPreferencesBox}
        onClick={() => openModal(MODAL_NAME)}
        data-type='country-flag-icon-footer'
      >
        <CountryFlagSvg
          customClassName={styles.userPreferencesCountryFlag}
          country={country?.toLowerCase()}
        />
        <div className={styles.userPreferencesText}>
          <span>{countryName}</span>
          {'/'}
          <span>{currencyCode}</span>
          {'/'}
          <span className={styles.userPreferencesUnit}>{userPreferencesMeasurementUnit}</span>
          <ArrowRightIcon />
        </div>
      </div>
      <PreferencesModal modalName={MODAL_NAME} />
    </>
  )
}

export default ModalConnector(UserPreferences(PreferencesFooter))
