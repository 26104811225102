import { createSelector } from 'reselect'
import type { Shared } from 'types/Shared'
import 'types/Shared'
import type { Navigation } from 'types/Shared/Navigation'
import 'types/Shared/Navigation'
import { getShared } from './helpers'
export const getNavigation = createSelector(
  [getShared],
  (shared: Shared): Navigation => shared.navigation || {}
)
export const getNavigationSearch = createSelector(
  [getNavigation],
  (navigation: Record<string, any>): Record<string, any> => navigation.search || {}
)
export const getNavigationSearchType = createSelector(
  [getNavigationSearch],
  (search: Record<string, any>): string => search.type || ''
)
export const getNavigationSearchValue = createSelector(
  [getNavigationSearch],
  (search: Record<string, any>): string => search.value || ''
)
// We want a way to IGNORE intial EPIC, we can pass in boolean to handle
export const isNavigationSearchCustom = createSelector(
  [getNavigationSearch],
  (search: Record<string, any>): string => search.custom || false
)