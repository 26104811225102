import React from 'react'
import styled from 'styled-components'
import { display } from 'styled-system'
import SessionConnect from 'containers/Session'
import Undo from 'svg/undo.svg'
const UndoIcon = styled(Undo)`
  width: 12px;
  height: 12px;
  margin-right: 5px;
`
const Indicator = styled.div`
  padding: 0 ${(props) => props.theme.space[4]}px;
  text-align: right;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes[1]}px;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  a {
    color: ${(props) => props.theme.colors.default};
    display: inline-block;
  }
  div, i {
    margin-right: 5px;
  }

  > * {
      text-decoration: none;
      display: block;
      padding: 12px 0;
  }
  ${display}
}
`
const Myself = styled.span`
  color: ${(props) => props.theme.colors.blue};
`
type Props = {
  isTakeover: Record<string, any> | null
  display: Array<string>
}

const TakeoverIndicator = (props: Props) => {
  if (!props.isTakeover) {
    return null
  }

  return (
    <Indicator display={props.display}>
      <a href='/accounts/untakeover'>
        <div>
          <UndoIcon />
          Return to being <Myself>myself</Myself>
        </div>
      </a>
    </Indicator>
  )
}

export default SessionConnect(TakeoverIndicator)