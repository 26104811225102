import url from 'url'
import { updateAppUrl } from 'lib/url/route'
import parse from 'lib/url/parse'
import { urlQuery, objectToQueryString } from 'lib/helpers'
import { DEFAULT_APP, SECTION } from './constants'
// We need a way to pull SPECIAL Query Params for ALGOLIA
// Ex: hitsPerPage, page, sort
export const getPageParamsQuery = (href: string = ''): Record<string, any> => {
  const { query } = url.parse(href, true)
  const { hitsPerPage, page } = query || {}
  return {
    hitsPerPage,
    page,
  }
}
// Location URL
// queryParams merged attributes to create url  => ...selectedFacets, ...algoliaQuery, ...appQuery, ...params
export const generateLocationUrl = ({
  urlPathString,
  queryParams,
}: Record<string, any>): string => {
  const queryString = urlQuery(queryParams)
  return `${urlPathString}${queryString}`
}
export const updateStudioSalesUrl = (
  href: string,
  section: string,
  subSection?: string,
  artworkId?: string
): void => {
  console.log({
    href,
    section,
    subSection,
  })
  const { query } = parse(href) || {}
  const app = `${DEFAULT_APP}?${objectToQueryString({
    section: section || SECTION,
    subSection,
    artworkId,
    ...query,
  })}`
  updateAppUrl({
    app,
    href,
  })
}