import React, { FC, useEffect, useState } from 'react'
import SearchConnector, { ConnectorProps as SearchProps } from 'connectors/navigation/Search'
import styles from './SearchInput.module.scss'
import SearchIcon from 'svg/search-icon.svg'
import classNames from 'classnames'
import ClearIcon from 'svg/close.svg'

type SearchInputProps = SearchProps & {
  onSearch: (query: string) => void
}

const SearchInput: FC<SearchInputProps> = ({ onSearch, searchQuery }) => {
  const [query, setQuery] = useState<string>('')
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const handleSearch = () => {
    onSearch(query)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleClearInput = () => {
    setQuery('')
  }

  useEffect(() => {
    if (query !== searchQuery) {
      setQuery(searchQuery)
    }
  }, [searchQuery])

  return (
    <div
      className={classNames(styles.searchField, {
        [styles.searchFieldFocused]: isFocused,
      })}
      data-type='search-input'
    >
      <div className={styles.searchIconBox} onClick={handleSearch}>
        <SearchIcon />
      </div>
      <input
        type='text'
        value={query}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder='Search art or artist'
      />
      {query !== '' && (
        <div className={styles.clearInputBox} onClick={handleClearInput}>
          <ClearIcon />
        </div>
      )}
    </div>
  )
}

export default SearchConnector(SearchInput)
