import { updateCriticalImagePath } from 'lib/helpers'

type NavigationLinkImage = {
  title: string
  url: string
  linkTo: string
}

type LinkObject = {
  title: string
  url: string
}

export type NavigationLinksObject = {
  title: string
  links: ReadonlyArray<LinkObject>
}

export type NavigationLinksCategories = {
  title: string
  url: string
  image: NavigationLinkImage
  links: ReadonlyArray<NavigationLinksObject>
}

const SUB_FOLDER = `saatchi-general`

export const NavigationLinks: ReadonlyArray<NavigationLinksCategories> = [
  {
    title: 'Paintings',
    url: '/paintings',
    image: {
      url: updateCriticalImagePath(
        SUB_FOLDER,
        'https://d3t95n9c6zzriw.cloudfront.net/navigation/dropdown/v2/saatchi-featured-paintings-v4.jpg'
      ),
      title: 'Featured Paintings',
      linkTo: 'https://www.saatchiart.com/art-collection/Featured-Paintings/1754780/657827/view',
    },
    links: [
      {
        title: 'Style',
        links: [
          {
            title: 'Fine Art',
            url: '/paintings/fine-art',
          },
          {
            title: 'Abstract',
            url: '/paintings/abstract',
          },
          {
            title: 'Modern',
            url: '/paintings/modern',
          },
          {
            title: 'Street Art',
            url: '/paintings/street-art',
          },
          {
            title: 'Pop Art',
            url: '/paintings/pop-art',
          },
        ],
      },
      {
        title: 'Subject',
        links: [
          {
            title: 'Portrait',
            url: '/paintings/portrait',
          },
          {
            title: 'Landscape',
            url: '/paintings/landscape',
          },
          {
            title: 'Still Life',
            url: '/paintings/still-life',
          },
          {
            title: 'Nature',
            url: '/paintings/nature',
          },
          {
            title: 'Beach',
            url: '/paintings/beach',
          },
        ],
      },
      {
        title: 'Medium',
        links: [
          {
            title: 'Oil',
            url: '/paintings/oil',
          },
          {
            title: 'Watercolor',
            url: '/paintings/watercolor',
          },
          {
            title: 'Acrylic',
            url: '/paintings/acrylic',
          },
          {
            title: 'Airbrush',
            url: '/paintings/airbrush',
          },
          {
            title: 'Ink',
            url: '/paintings/ink',
          },
        ],
      },
    ],
  },
  {
    title: 'Photography',
    url: '/photography',
    image: {
      url: updateCriticalImagePath(
        SUB_FOLDER,
        'https://d3t95n9c6zzriw.cloudfront.net/navigation/dropdown/v2/saatchi-featured-photography-v4.jpg'
      ),
      title: 'Featured Photography',
      linkTo: 'https://www.saatchiart.com/art-collection/Featured-Photography/1586325/615783/view',
    },
    links: [
      {
        title: 'Style',
        links: [
          {
            title: 'Fine Art',
            url: '/photography/fine-art',
          },
          {
            title: 'Portraiture',
            url: '/photography/portraiture',
          },
          {
            title: 'Abstract',
            url: '/photography/abstract',
          },
          {
            title: 'Documentary',
            url: '/photography/documentary',
          },
          {
            title: 'Conceptual',
            url: '/photography/conceptual',
          },
        ],
      },
      {
        title: 'Subject',
        links: [
          {
            title: 'Landscape',
            url: '/photography/landscape',
          },
          {
            title: 'Portrait',
            url: '/photography/portrait',
          },
          {
            title: 'Nature',
            url: '/photography/nature',
          },
          {
            title: 'Still Life',
            url: '/photography/still-life',
          },
          {
            title: 'Nude',
            url: '/photography/nude',
          },
        ],
      },
      {
        title: 'Medium',
        links: [
          {
            title: 'Digital',
            url: '/photography/digital',
          },
          {
            title: 'Black and White',
            url: '/photography/black-white',
          },
          {
            title: 'Color',
            url: '/photography/color',
          },
          {
            title: 'C-type',
            url: '/photography/c-type',
          },
          {
            title: 'Digital',
            url: '/photography/gelatin',
          },
        ],
      },
    ],
  },
  {
    title: 'Sculpture',
    url: '/sculpture',
    image: {
      url: updateCriticalImagePath(
        SUB_FOLDER,
        'https://d3t95n9c6zzriw.cloudfront.net/navigation/dropdown/v2/saatchi-featured-sculpture-v4.jpg'
      ),
      title: 'Featured Sculptures',
      linkTo:
        'https://www.saatchiart.com/art-collection/sculpture-painting/Featured-Sculptures/1861756/726057/view',
    },
    links: [
      {
        title: 'Style',
        links: [
          {
            title: 'Pop Art',
            url: '/sculpture/pop-art',
          },
          {
            title: 'Abstract',
            url: '/sculpture/abstract',
          },
          {
            title: 'Wall',
            url: '/sculpture/wall',
          },
          {
            title: 'Figurative',
            url: '/sculpture/figurative',
          },
          {
            title: 'Modern',
            url: '/sculpture/modern',
          },
        ],
      },
      {
        title: 'Subject',
        links: [
          {
            title: 'Body',
            url: '/sculpture/body',
          },
          {
            title: 'Still Life',
            url: '/sculpture/still-life',
          },
          {
            title: 'Animal',
            url: '/sculpture/animal',
          },
          {
            title: 'Architecture',
            url: '/sculpture/architecture',
          },
          {
            title: 'Geometric',
            url: '/sculpture/geometric',
          },
        ],
      },
      {
        title: 'Medium',
        links: [
          {
            title: 'Metal',
            url: '/sculpture/metal',
          },
          {
            title: 'Bronze',
            url: '/sculpture/bronze',
          },
          {
            title: 'Clay',
            url: '/sculpture/clay',
          },
          {
            title: 'Glass',
            url: '/sculpture/glass',
          },
          {
            title: 'Wood',
            url: '/sculpture/wood',
          },
        ],
      },
    ],
  },
  {
    title: 'Drawings',
    url: '/drawings',
    image: {
      url: updateCriticalImagePath(
        SUB_FOLDER,
        'https://d3t95n9c6zzriw.cloudfront.net/navigation/dropdown/v2/saatchi-featured-drawing-v4.jpg'
      ),
      title: 'Featured Drawings',
      linkTo: 'https://www.saatchiart.com/art-collection/New-Featured-Drawings/1012074/615613/view',
    },
    links: [
      {
        title: 'Style',
        links: [
          {
            title: 'Graffiti',
            url: '/drawings/graffiti',
          },
          {
            title: 'Abstract',
            url: '/drawings/abstract',
          },
          {
            title: 'Fine Art',
            url: '/drawings/fine-art',
          },
          {
            title: 'Pop Art',
            url: '/drawings/pop-art',
          },
          {
            title: 'Surrealism Art',
            url: '/drawings/surrealism',
          },
        ],
      },
      {
        title: 'Subject',
        links: [
          {
            title: 'Nature',
            url: '/drawings/nature',
          },
          {
            title: 'Portrait',
            url: '/drawings/portrait',
          },
          {
            title: 'Animal',
            url: '/drawings/animal',
          },
          {
            title: 'Love',
            url: '/drawings/love',
          },
          {
            title: 'Cartoon',
            url: '/drawings/cartoon',
          },
        ],
      },
      {
        title: 'Medium',
        links: [
          {
            title: 'Pencil',
            url: '/drawings/pencil',
          },
          {
            title: 'Charcoal',
            url: '/drawings/charcoal',
          },
          {
            title: 'Digital',
            url: '/drawings/digital',
          },
          {
            title: 'Ink',
            url: '/drawings/ink',
          },
          {
            title: 'Pastel',
            url: '/drawings/pastel',
          },
        ],
      },
    ],
  },
  {
    title: 'Prints',
    url: '/prints',
    image: {
      url: updateCriticalImagePath(
        SUB_FOLDER,
        'https://d3t95n9c6zzriw.cloudfront.net/navigation/dropdown/v2/saatchi-featured-prints-v4.jpg'
      ),
      title: 'Fine Art Prints',
      linkTo: 'https://www.saatchiart.com/stories/fine-art-prints',
    },
    links: [
      {
        title: 'Style',
        links: [
          {
            title: 'Fine Art',
            url: '/prints/fine-art',
          },
          {
            title: 'Abstract',
            url: '/prints/abstract',
          },
          {
            title: 'Art Deco',
            url: '/prints/art-deco',
          },
          {
            title: 'Pop Art',
            url: '/prints/pop-art',
          },
          {
            title: 'Folk Art',
            url: '/prints/folk',
          },
        ],
      },
      {
        title: 'Subject',
        links: [
          {
            title: 'Floral',
            url: '/prints/floral',
          },
          {
            title: 'Animal',
            url: '/prints/animal',
          },
          {
            title: 'Fashion',
            url: '/prints/fashion',
          },
          {
            title: 'Beach',
            url: '/prints/beach',
          },
          {
            title: 'Geometric',
            url: '/prints/geometric',
          },
        ],
      },
      {
        title: 'Material',
        links: [
          {
            title: 'Fine Art Paper',
            url: '/prints?prints_materials=rag',
          },
          {
            title: 'Photo Paper',
            url: '/prints?prints_materials=photo',
          },
          {
            title: 'Canvas',
            url: '/prints?prints_materials=canvas',
          },
          {
            title: 'Metal',
            url: '/prints?prints_materials=metal',
          },
          {
            title: 'Acrylic',
            url: '/prints?prints_materials=acrylic',
          },
        ],
      },
    ],
  },
  {
    title: 'Inspiration',
    url: '/stories',
    image: {
      title: 'Featured Collections',
      url: updateCriticalImagePath(
        SUB_FOLDER,
        'https://d3t95n9c6zzriw.cloudfront.net/navigation/dropdown/v2/saatchi-featured-inspiration-v4.jpg'
      ),
      linkTo: '/collections/featured',
    },
    links: [
      {
        title: '',
        links: [
          {
            title: 'Curated Collections',
            url: '/collections/featured',
          },
          {
            title: 'Digital Exhibitions',
            url: '/shows',
          },
          {
            title: 'Catalog',
            url: '/catalog',
          },
          {
            title: 'Art Stories',
            url: '/stories',
          },
          {
            title: 'Trending Artists',
            url: '/trendingartists',
          },
          {
            title: 'Living With Art',
            url: '/livingwithart',
          },
        ],
      },
      {
        title: '',
        links: [
          {
            title: 'Rising Stars',
            url: '/risingstars',
          },
          {
            title: 'The Other Art Fair',
            url: '/theotherartfair',
          },
          {
            title: 'Art Match',
            url: '/artmatch',
          },
          {
            title: 'Art Style Quiz',
            url: '/quiz/art-for-your-style-quiz ',
          },
          {
            title: 'Blog',
            url: '//canvas.saatchiart.com/',
          },
        ],
      },
    ],
  },
  {
    title: 'Art Advisory',
    url: '/artadvisory',
    image: {
      title: 'Find Art You Love',
      url: updateCriticalImagePath(
        SUB_FOLDER,
        'https://d3t95n9c6zzriw.cloudfront.net/navigation/dropdown/v2/saatchi-featured-art-advisory-v4.jpg'
      ),
      linkTo: '/stories/how-to-find-the-perfect-artwork',
    },
    links: [
      {
        title: '',
        links: [
          { title: 'About Art Advisory', url: '/artadvisory' },
          { title: 'Meet The Experts', url: '/artadvisory#team' },
          { title: 'Commission An Artwork', url: '/all' },
          { title: 'Contact Art Advisors', url: '/artadvisory/brief' },
        ],
      },
    ],
  },
  {
    title: 'Trade',
    url: '/trade',
    image: {
      title: 'Saatchi Art for Business',
      url: updateCriticalImagePath(
        SUB_FOLDER,
        'https://d3t95n9c6zzriw.cloudfront.net/navigation/dropdown/v2/saatchi-featured-trade-v4.jpg'
      ),
      linkTo: '/trade/trade-program',
    },
    links: [
      {
        title: '',
        links: [
          {
            title: 'Saatchi Art For Business ',
            url: '/trade',
          },
          {
            title: 'Trade Program',
            url: '/trade/trade-program',
          },
          {
            title: 'Hospitality',
            url: '/trade/hospitality',
          },
          {
            title: 'Contact Art Consultants',
            url: '/trade-hospitality#contact',
          },
        ],
      },
    ],
  },
  {
    title: 'Gifts',
    url: '/gift-guide',
    image: {
      title: '',
      url: '',
      linkTo: '',
    },
    links: [],
  },
]

export const FEATURED_CATEGORIES_MAPPING = {
  ['Paintings']: 'FEATURED PAINTINGS',
  ['Photography']: 'FEATURED PHOTOGRAPHY',
  ['Sculpture']: 'FEATURED SCULPTURE',
  ['Drawings']: 'FEATURED DRAWINGS',
  ['Prints']: 'FINE ART PRINTS',
  ['Inspiration']: 'FEATURED COLLECTIONS',
  ['Art Advisory']: 'HOW TO BUY ART YOU LOVE',
  ['Trade']: 'TRADE PROGRAM',
  ['']: '',
}
