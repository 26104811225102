import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
const Wrapper = styled.div`
  ${space};
  margin: 20px auto;
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Loader = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  border: 5px solid ${(props) => props.theme.colors.backgroundGray};
  border-top: 5px solid ${(props) => props.theme.colors.backgroundMediumGray};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
`
export const DynamicLoader = () => {
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  )
}
export default {
  ssr: false,
  loading: () => <DynamicLoader />,
} // noSSR