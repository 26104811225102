import React from 'react'
import { convertFromCentsToDollars } from 'components/FormatPrice/helpers'
import LocaleCurrency from 'components/FormatPrice/LocaleCurrency'
type Props = {
  product: Record<string, any>
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { product } = props
  const { artistFirstName, artistLastName, price, productName, thumbnail } = product
  const artistFullName = `${artistFirstName} ${artistLastName}`
  const { totalPriceToPay } = price
  const squareThumbnail = thumbnail.replace(/-\d*.jpg$/, '-6.jpg')
  return (
    <div data-type='cart-item'>
      <div data-type='image'>
        <img src={squareThumbnail} title={`${productName} by ${artistFullName}`} />
      </div>

      <div data-section='info'>
        <p data-type='title'>{productName}</p>

        <div data-type='price'>
          <LocaleCurrency decimals price={convertFromCentsToDollars(totalPriceToPay)} />
        </div>
      </div>
    </div>
  )
}

export default Component