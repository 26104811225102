import CloseX from 'svg/close-x.svg'
import React from 'react' // eslint-disable-line

import styled from 'styled-components'
export const CartUrgencyWrapper = styled.div`
  &[data-animation='fadeIn'] {
    animation: fadeIn 1s ease-in-out forwards;
  }
  &[data-animation='fadeOut'] {
    animation: fadeOut 1s ease-in-out forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  // needs to start out of the page and be displayed after the animation
  position: absolute;
  top: -90px;
  right: 54px;
  opacity: 0;
  display: flex;
  background-color: #1e1e1e;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  padding: 0px 10px;
  border-radius: 6px;

  @media (max-width: 999px) {
    top: var(--sticky-bar-height);
    right: 14px;
    width: 95%;
    max-width: 360px;
    align-items: flex-start;
  }

  a {
    &[data-style='cart-link'] {
      color: #ffffff;
    }
  }
`
export const CartUrgencyTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  gap: 10px;

  @media (max-width: 999px) {
    padding: 6px 8px;
    flex-wrap: wrap;
    gap: 5px;
  }

  [data-style='paragraph-wp'] {
    margin: 0; // in wordpress pages needs to override margin of p
  }
`
export const CartUrgencyCloseIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5%;

  div {
    &[data-style='icon-clickable-div'] {
      cursor: pointer;
      padding: 8px;

      @media (max-width: 999px) {
        padding: 6px 8px;
      }
    }
  }
`
export const CloseIcon = styled(CloseX)`
  height: 11px;
  width: 11px;
  path {
    stroke: #ffffff;
    stroke-width: 1.5px;
  }
`
