import React from 'react'
import ArrowRightIcon from 'svg/chevron-right.svg'
import styles from './MobileCategories.module.scss'
import HR from 'components/HR'
import classNames from 'classnames'
import { useHeaderV2Context } from 'components/HeaderV2/HeaderV2Context'
import SALink from 'components/SALink'
import { FEATURED_CATEGORIES_MAPPING } from 'components/HeaderV2/NavigationLinks'

const CATEGORIES_WITH_VIEW_ALL = ['Paintings', 'Photography', 'Sculpture', 'Drawings', 'Prints']

const MobileCategoriesOptions = () => {
  const {
    setSelectedCategory,
    selectedCategory,
    setMobileHeaderConfig,
    navigationLinks,
  } = useHeaderV2Context()

  const navigationCategory = navigationLinks.find(({ title }) => title === selectedCategory)

  const handleBackToCategory = () => {
    setMobileHeaderConfig((previousMobileHeaderConfig) => ({
      ...previousMobileHeaderConfig,
      mobileMenuContent: 'categories',
    }))
    setSelectedCategory(null)
  }

  return (
    <div className={styles.mobileCategoriesOptionsContainer}>
      <div className={styles.mobileCategoriesOptionsTitleBox}>
        <div className={styles.categoryBackIconBox} onClick={handleBackToCategory}>
          <ArrowRightIcon className={styles.mobileCategoriesOptionsArrowIcon} />
        </div>
        <div className={styles.categoryTitleBox}>
          <h3 className={classNames('defaultTypographyH5', 'defaultTextCenter')}>
            {selectedCategory}
          </h3>
        </div>
      </div>
      {selectedCategory && CATEGORIES_WITH_VIEW_ALL.includes(selectedCategory) && (
        <div className={styles.mobileCategoriesOptionsViewAll}>
          <SALink
            data-gtm-event='top-nav'
            className={classNames('defaultTypographyCtaMedium', 'defaultLink')}
            href={navigationCategory?.url}
          >
            VIEW ALL
          </SALink>
          <HR />
        </div>
      )}
      {!!navigationCategory &&
        navigationCategory.links.map(({ title, links: subcategoryLinks }, index) => (
          <ul
            className={classNames(styles.mobileCategoriesOptionsBox, {
              [styles.mobileCategoriesOptionsBoxNoTitle]: index !== 0 && title === '', // only to fix spacing when there is no title in sections tha are not the first one
            })}
            key={`${title}${index}`}
          >
            {title !== '' && <span className='defaultTypographyCtaMedium'>{title}</span>}
            <li
              className={classNames(styles.mobileCategoriesOptionsSubcategoryBox, {
                [styles.mobileCategoriesOptionsSubcategoryBoxNoTitle]: title === '',
              })}
            >
              {subcategoryLinks.map(({ title: optionTitle, url: optionUrl }, subcategoryIndex) => (
                <SALink
                  key={`${optionTitle}${subcategoryIndex}`}
                  className={classNames('defaultTypographyMedium', 'defaultLink')}
                  href={optionUrl}
                  data-gtm-event='top-nav'
                >
                  {optionTitle}
                </SALink>
              ))}
            </li>
            {index + 1 < navigationCategory.links.length ? (
              navigationCategory.links[index + 1].title !== '' && <HR />
            ) : (
              <HR />
            )}
          </ul>
        ))}
      <div>
        <SALink
          className={classNames('defaultLink', styles.mobileCategoriesOptionsImageCta)}
          href={navigationCategory?.image.linkTo}
          data-gtm-event='top-nav'
        >
          <img src={navigationCategory?.image.url} />
          <div className={styles.featuredCategoryBox}>
            <span className={classNames('defaultTypographyCtaMedium', 'defaultTextCenter')}>
              {FEATURED_CATEGORIES_MAPPING[selectedCategory || '']}
            </span>
            <span
              className={classNames('defaultTypographyCtaSmall', styles.featuredCategoryBoxView)}
            >
              VIEW
            </span>
          </div>
        </SALink>
      </div>
    </div>
  )
}

export default MobileCategoriesOptions
