import React from 'react'
import styles from '../Modals/Preferences.module.scss'
import CaretDownIcon from 'svg/caretDown.svg'
type Props = {
  data: Record<string, any>
  selected: string
  onChange: (...args: Array<any>) => any
}
const NAME = 'currency'

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { data, onChange, selected } = props
  const dataArray = Object.entries(data) || []
  return (
    <div className={styles.preferencesInputBox}>
      <label htmlFor={NAME} className={styles.preferencesInputLabel}>
        <span>Currency*</span>
        <div className={styles.preferencesInputField}>
          <input type='text' value={data[selected]} disabled />
          <CaretDownIcon />
        </div>
      </label>

      <select
        className='defaultInputSelectField'
        name={NAME}
        id={NAME}
        value={selected}
        onChange={onChange}
        tabIndex={0}
      >
        {dataArray.map((item) => {
          const [key, name] = item
          return (
            <option key={key} value={key}>
              {String(name)}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default Component
