import React, { useEffect, useState } from 'react'
import AuthenticationConnector, {
  ConnectorProps as AuthenticationProps,
} from 'connectors/Authentication'
import SALink from 'components/SALink'
import styles from './Account.module.scss'
import classNames from 'classnames'
import { useHeaderV2Context } from 'components/HeaderV2/HeaderV2Context'
type Props = AuthenticationProps & {
  dropdownIsOpen: boolean
  dropdownIsHovered: boolean
}

const AuthLinksDropDown = (props: Props) => {
  const { showModal, dropdownIsOpen, dropdownIsHovered, openModal } = props
  const { mobileHeaderConfig, setMobileHeaderConfig } = useHeaderV2Context()
  const [location, updateLocation] = useState('/')
  useEffect(() => {
    const { href } = window.location
    updateLocation(href)
  })

  return (
    <div
      className={classNames(styles.accountDropDownContent, styles.accountDropDownContentAuth, {
        [styles.accountDropDownContentOpen]:
          dropdownIsOpen ||
          dropdownIsHovered ||
          (mobileHeaderConfig.isMobileMenuOpen && mobileHeaderConfig.mobileMenuContent === 'auth'),
      })}
    >
      <SALink
        data-gtm-event='top-nav'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAuthLink)}
        href={`/authentication/?redirect_url=${encodeURIComponent(location)}`}
        onClick={(ev: React.SyntheticEvent<Record<string, any>>) => {
          ev.preventDefault()
          openModal('login')
          setMobileHeaderConfig((previousMobileHeaderConfig) => ({
            ...previousMobileHeaderConfig,
            mobileTopHeader: 'mainTopHeader',
            isMobileMenuOpen: false,
          }))
        }}
        rel='nofollow'
      >
        Log In
      </SALink>

      <SALink
        data-gtm-event='top-nav'
        className={classNames('defaultTypographyMedium', styles.accountDropDownContentAuthLink)}
        href={`/authentication/register/?redirect_url=${encodeURIComponent(location)}`}
        onClick={(ev: React.SyntheticEvent<Record<string, any>>) => {
          ev.preventDefault()
          openModal('register')
          setMobileHeaderConfig((previousMobileHeaderConfig) => ({
            ...previousMobileHeaderConfig,
            mobileTopHeader: 'mainTopHeader',
            isMobileMenuOpen: false,
          }))
        }}
        rel='nofollow'
      >
        Register
      </SALink>
    </div>
  )
}

export default AuthenticationConnector(AuthLinksDropDown)
