import styled from 'styled-components'
import { space } from 'styled-system'
export const HR = styled.hr`
  border: 0;
  height: 1px;
  background: ${(props) => props.theme.colors.footerBackground};
  width: 100%;
  margin: 0;
  padding: 0;
  ${(props) => props.styles};
  ${space}
`
export default HR