import fetch from 'isomorphic-fetch'
import { getOptions, parseJSON } from 'apis/helpers'
export const fetchClientData = async (): Promise<Record<string, any>> => {
  const response = await fetch(`/easel_api/client-data`, getOptions)
  const json = await parseJSON(response, 'fetchClientData')
  return json
}
export const fetchAllClientData = async (): Promise<Record<string, any>> => {
  const response = await fetch(`/easel_api/all-client-data`, getOptions)
  const json = await parseJSON(response, 'fetchAllClientData')
  return json
}