import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getIsAdmin } from 'selectors/user/session'
import { Actions } from 'actions/shared/modal'
import { getModalIsVisible, getModalName, getIsCurrentModalAvailable } from 'selectors/shared/modal'
const mapStateToProps = createStructuredSelector({
  isAdmin: getIsAdmin,
  isModalAvailable: (store, props) => getIsCurrentModalAvailable(props.name)(store),
  isVisible: getModalIsVisible,
  name: getModalName,
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(Actions.CLOSE_MODAL()),
  openModal: (payload: string) => dispatch(Actions.OPEN_MODAL(payload)),
  resetModal: () => dispatch(Actions.RESET_MODAL()),
})

export type ConnectorProps = {
  closeModal: (...args: Array<any>) => any
  isAdmin: boolean
  isModalAvailable: boolean
  isVisible: boolean
  openModal: (...args: Array<any>) => any
  resetModal: (...args: Array<any>) => any
  name: string
}
export default connect(mapStateToProps, mapDispatchToProps)