import React from 'react'
import styles from './CountryFlag.module.scss'
import { FLAGS_MAPPING } from './FlagsSvg'
import classNames from 'classnames'

type CountryFlagSvgProps = {
  country: string
  customClassName?: string
}

const CountryFlagSvg = (props: CountryFlagSvgProps) => {
  const { country, customClassName = '' } = props
  const COUNTRY_NAME = `${(country || '').toLowerCase()}Flag`
  const FlagComponent = FLAGS_MAPPING[COUNTRY_NAME] || (() => null)

  return (
    <div
      className={classNames(styles.flagSvg, {
        [styles.flagSize]: customClassName === '',
        [customClassName]: customClassName !== '',
      })}
    >
      <FlagComponent />
    </div>
  )
}

export default CountryFlagSvg
