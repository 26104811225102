import { Actions as ClientActions } from 'actions/shared/client'
import { fetchAllClientData } from 'apis/client'
import { get as getStorage, set as setStorage } from 'lib/localStorage'
import parse from 'lib/url/parse'

const STORAGE_KEY = 'saatchi_client_data'
const EXPIRATION = 1000 * 60 * 5 // ms * s * m => 5 minutes

const getServerClientData = async (): Promise<Record<string, any>> => {
  const parsedURL = parse(window.location.href)
  const { query } = parsedURL

  if (query && query.delay) {
    // Add ability to delay session loading for WebVitals/Lighthouse
    await new Promise((resolve) => setTimeout(resolve, 1000 * Number(query.delay)))
  }

  const { success, payload } = await fetchAllClientData()
  // Update Cookies for Algolia Personalization
  return {
    ...(success ? payload : {}),
    client: {
      setup: true,
    }, // instana: { traceId: '' },
  }
}

/*
 * Basic GTM actions we NEED to fire on all pages once SESSION is ready
 */
export const updateGtmDatalayer = (data: Record<string, any>): void => {
  const { session, cookie } = data
  const { userPreferences } = cookie
  const { country } = userPreferences
  const preferredLocale = `en-${country || 'US'}`

  // isAuthenticated
  if (session.userId) {
    const { email, userRole, userId, checkInTimerStartedAt }: Record<string, any> = session
    window.dataLayer.push({
      event: 'get_gtm_vars',
      gtm_vars: {
        checkin_date: checkInTimerStartedAt,
      },
    })
    window.dataLayer.push({
      'user category': userRole || 'guest',
      'user email': email || '',
      'user id': userId || '',
    })
  }

  window.dataLayer.push({
    preferred_locale: preferredLocale, // "en-US"
  })
  window.dispatchEvent(new Event('userLoaded'))
  window.dataLayer.push({
    event: 'userLoaded',
  })
}

const checkExpiration = (expiration: number): boolean => {
  const currentTime = new Date().getTime()
  const isExpired = expiration < currentTime
  return isExpired
}

//
const checkLocalStorage = (): { clientData: object; isValid: boolean } => {
  // Check if USER just logged in - Redirected from AUTH
  // Does NOT work if user is REDIRECTED ( Authenticated-User: true )
  const checkLogin = document.referrer.search(/authentication/) > -1

  // Check Local Storage
  const clientData = getStorage(STORAGE_KEY) || {}

  // Check Expiration
  const { expiration } = clientData
  const isExpired = checkExpiration(expiration || 0)

  // Existing APP & has NOT expired & Not redirected from AUTH - We don't need to FETCH or Fire GTM events
  return {
    clientData,
    isValid: !isExpired && !checkLogin,
  }
}

export const setClientStorage = (clientData: Object) => {
  const currentTime = new Date().getTime()
  setStorage(STORAGE_KEY, {
    ...clientData,
    expiration: new Date(currentTime + EXPIRATION).getTime(),
  })
}

export const resetClientStorage = () => {
  setStorage(STORAGE_KEY, {
    expiration: 0,
  })
}

export const initialLoad = async ({ store, hasSession, allClientData }): Promise<void> => {
  // Skip all logic if PRIVATE page ( req has client data attached )
  if (hasSession) {
    // This will occur from PRIVATE Pages ( Ex: Cart ), don't need to fetch data
    setClientStorage(allClientData)
    store.dispatch(ClientActions.NEW_CLIENT_COMPLETE())
    return
  }

  // handling reloading local storage from legacy redirect
  // TODO - REBUILD LEGACY - after rebuild pages, drop this logic
  let shouldReloadSession = false
  if (document && document.referrer) {
    shouldReloadSession = document.referrer.search('/accounts/settings') > -1
  }

  const { isValid, clientData: localData } = checkLocalStorage()
  let clientData = { ...localData }

  // Admin Logic - Always Refresh Client - Issue Takeover Logic
  const { session } = clientData
  let isAdmin = false
  if (session?.isAuthenticated) {
    isAdmin = session.isAdmin || session.adminId
  }

  // QA Testing
  const isSaatchiBot = (window.navigator.userAgent || '').search(/Saatchibot/i) > -1

  // Refetch
  if (!isValid || isAdmin || isSaatchiBot || shouldReloadSession) {
    clientData = await getServerClientData()
    setClientStorage(clientData)
  }

  // Update REDUX State
  store.dispatch(ClientActions.NEW_CLIENT_COMPLETE(clientData))
  // DataLayer Events
  updateGtmDatalayer(clientData)
}

export const revalidateClient = async ({ store }: Object): Promise<void> => {
  const { isValid, clientData: localData } = checkLocalStorage()
  let clientData = { ...localData }
  // Refetch
  if (!isValid) {
    clientData = await getServerClientData()
    setClientStorage(clientData)
    // Update REDUX State - This is just an UPDATE
    store.dispatch(ClientActions.NEW_CLIENT_COMPLETE(clientData))
  }
}
