import React, { FC } from 'react'
import UserPreferences, { ConnectorProps as UserPreferencesProps } from 'connectors/UserPreferences'
import PreferencesModal from 'components/Preferences/Modals/Preferences'
import styles from './CountryFlagIcon.module.scss'
import CountryFlagSvg from 'components/CountryFlag/CountryFlagSvg'
import ModalConnector, { ConnectorProps as ModalProps } from 'containers/Modal'
import classNames from 'classnames'

type CountryFlagIconProps = UserPreferencesProps &
  ModalProps & { modalName: string; customClassName?: string }

const CountryFlagIcon: FC<CountryFlagIconProps> = ({
  userPreferences,
  openModal,
  modalName,
  customClassName = '',
}) => {
  return (
    <>
      <div
        title='View Store Preferences'
        className={classNames(styles.countryFlagBox, {
          [customClassName]: customClassName !== '',
        })}
        onClick={() => openModal(modalName)}
        data-type='country-flag-icon'
      >
        <CountryFlagSvg
          country={userPreferences?.country?.toLowerCase()}
          customClassName={customClassName}
        />
      </div>
      <PreferencesModal modalName={modalName} />
    </>
  )
}

export default ModalConnector(UserPreferences(CountryFlagIcon))
