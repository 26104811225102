import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getNavigationSearchType, getNavigationSearchValue } from 'selectors/shared/navigation'
import { getPageParamsQuery } from 'selectors/page'
const mapStateToProps = createStructuredSelector({
  searchType: getNavigationSearchType,
  searchValue: getNavigationSearchValue,
  // Initial Search Value
  searchQuery: getPageParamsQuery, // Clientside Page Transitions
})

const mapDispatchToProps = (dispatch) => ({})

export type ConnectorProps = {
  searchType: string
  searchValue: string
  searchQuery: string
}
export default connect(mapStateToProps, mapDispatchToProps) // Component Calls Connect