import React, { FC, PropsWithChildren } from 'react'
import styles from './Accordion.module.scss'
import classNames from 'classnames'
import PlusIcon from 'svg/heavier-plus.svg'
import { AccordionItemType } from './Accordion'

type AccordionItemProps = Pick<AccordionItemType, 'title' | 'headerCustomStyle'> & {
  activeIndex: string | null
  onChangeIndex: React.Dispatch<React.SetStateAction<string | null>>
}

const AccordionItem: FC<PropsWithChildren<AccordionItemProps>> = ({
  children,
  title,
  activeIndex,
  onChangeIndex,
  headerCustomStyle = '',
}) => {
  return (
    <div className={styles.accordionItemWrapper}>
      <input
        type='checkbox'
        id={`toggle-${title}`}
        className={styles.toggleInput}
        checked={activeIndex === title}
      />
      <label htmlFor={`toggle-${title}`}>
        <div className={styles.accordionItemContainer} onClick={() => onChangeIndex(title)}>
          <div
            className={classNames(styles.itemBox, {
              [styles.itemBoxVisible]: activeIndex === title,
              [headerCustomStyle]: headerCustomStyle !== '',
            })}
          >
            <span className='defaultTypographyH6'>{title}</span>
            <PlusIcon className={styles.expandCollapseIcon} />
          </div>
          <div
            className={classNames(styles.contentBox, {
              [styles.contentBoxVisible]: activeIndex === title,
            })}
          >
            {children}
          </div>
          <hr className='hrDivider' />
        </div>
      </label>
    </div>
  )
}

export default AccordionItem
