import React, { useMemo } from 'react'
import { useHeaderV2Context } from 'components/HeaderV2/HeaderV2Context'
import AccountTopHeader from './AccountTopHeader'
import MainTopHeader from './MainTopHeader'
import UserTopHeader from './UserTopHeader'
import SearchTopHeader from 'components/HeaderV2/Search/SearchTopHeader'

const HEADER_CONTENT = {
  ['mainTopHeader']: MainTopHeader,
  ['user']: UserTopHeader,
  ['account']: AccountTopHeader,
  ['search']: SearchTopHeader,
}

const TopHeader = () => {
  const { mobileHeaderConfig } = useHeaderV2Context()

  const ContentComponent = useMemo(() => HEADER_CONTENT[mobileHeaderConfig.mobileTopHeader], [
    mobileHeaderConfig.mobileTopHeader,
  ])

  return ContentComponent ? <ContentComponent /> : null
}

export default TopHeader
