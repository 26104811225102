import React from 'react'
import styles from './SALogo.module.scss'
import SaatchiLogo from 'svg/sa-header-logo.svg'
import SALink from 'components/SALink'

const SALogo = () => {
  return (
    <SALink
      className={styles.mobileSaLogoBox}
      alt='Saatchi Art logo'
      aria-label='Saatchi Art logo'
      data-type='logo'
      data-gtm-event='top-nav'
      href={process.env.SITE_URL || '/'}
    >
      <SaatchiLogo className={styles.mobileSaLogoIcon} />
    </SALink>
  )
}

export default SALogo
