import React from 'react' // eslint-disable-line

import styled from 'styled-components'
import ContextMenu, { ContextMenuActive } from 'components/ContextMenu'
export const DropdownMenu = styled(ContextMenu)`
  position: absolute;
  z-index: 10;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  min-width: 220px;
  flex-direction: column;
  pointer-events: none;

  [data-type='fullname'] {
    padding: 0 15px 5px;
    margin: 0 0 15px;
    border-bottom: 1px solid #dcdcdc;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    top: 40px;
    right: 0;
    &[data-hovered='true'] {
      ${ContextMenuActive}
      pointer-events: initial;
    }
    &[data-layout='inline'] {
      position: inherit;
      top: 0;
      left: initial;
      right: initial;
      flex-direction: row;
      padding: 0;
      box-shadow: none;
      pointer-events: none;
      opacity: 1;
      min-width: auto;
      pointer-events: inherit;
      a {
        width: auto;
      }
      &:before {
        display: none;
      }
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    top: 50px;
    right: -7px;
    min-width: 110px;
    &[data-visible='true'] {
      ${ContextMenuActive}
      pointer-events: initial;
    }
  }
`
export const AuthenticationWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 15px;

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    top: 18px;
  }

  [data-type='initials'] {
    display: none;
    font-weight: normal;
    font-size: 10px;
  }

  [data-type='line'] {
    display: block;
    width: 100%;
    margin: 15px 0;
    border-bottom: 1px solid #dcdcdc;
  }

  [data-type='account-links'] {
    padding: 0 20px;
    color: #333333;
    text-decoration: none;
    font-size: 16px;
    font-weight: normal;
    line-height: 32px;
    text-decoration: none;
  }
  [data-type='account-links'] + [data-type='account-links'] {
    border-left: none;
  }

  [data-layout='inline'] {
    [data-type='account-links'] {
      font-size: 14px;
      line-height: initial;
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        font-weight: normal;
      }
    }
    [data-type='account-links'] + [data-type='account-links'] {
      border-left: 2px solid #333333;
      margin: 0;
      padding: 0 10px;
      @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
        border-left: none;
        padding: 7px 5px 0 15px;
      }
    }
  }

  [data-type='dropdown'] {
    a {
      width: 100%;
    }
    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      h3 {
        display: none;
      }
    }
    @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
      &:before {
        content: '▲';
        filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px -4px 3px);
        font-size: 26px;
        color: #ffffff;
        position: absolute;
        top: -30px;
        right: 5px;
      }
      &[data-layout='inline'] {
        min-width: 110px;
      }
      h3 {
        display: flex;
      }
      a {
        font-size: 15px;
        padding: 0 15px;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    [data-type='account-icon'] {
      display: none;
    }
    [data-type='fullname'] {
      display: flex;
      font-weight: bold;
      font-size: 14px;
      padding: 10px 0;
    }
    [data-type='initials'] {
      display: none;
    }
    h3 {
      display: none;
    }
  }

  /* Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    [data-type='account-links'] {
      font-weight: normal;
      font-size: 14px;
      padding: 0 16px;
    }
    [data-type='account-icon'],
    [data-type='account-info'] {
      display: flex;
      width: 23px;
      height: 23px;
      padding: 0;
      align-items: center;
      justify-content: center;
      &[data-auth='true'] {
        border: 1px solid #ffffff;
        border-radius: 50%;
        color: #ffffff;
      }
    }
    [data-type='fullname'] {
      display: none;
    }
    [data-type='initials'] {
      display: flex;
      color: #ffffff;
    }
  }
`