import React, { useEffect, useState } from 'react'
import debounce from 'lib/debounce'
import { fetchCart } from 'apis/cart'
import type { ConnectorProps as CartProps } from 'connectors/Cart'
import CartConnector from 'connectors/Cart'
import CloseIcon from 'svg/close-x.svg'
import Portal from './Portal'
import ViewProducts from './ViewProducts'
const cartDebounce = debounce()
const DEFAULT_STATE = {
  cart: {},
  productSku: null,
}

const getCartItems = async (
  productSku: string | null | undefined,
  callBack: (...args: Array<any>) => any
): Promise<void> => {
  const { payload, success } = await fetchCart()
  callBack(
    success
      ? {
          cart: payload,
          productSku,
        }
      : DEFAULT_STATE
  )
}

const clearCartItems = async (callBack: (...args: Array<any>) => any): Promise<void> => {
  cartDebounce(() => {
    callBack(DEFAULT_STATE)
  }, 2500)
}

const Component = (props: CartProps): React.ReactElement<React.ComponentProps<any>, any> => {
  const { quickView, showCart } = props
  const { productSku, show } = quickView
  const [state, handleState] = useState({ ...DEFAULT_STATE, productSku })
  // Issue with the `productSku` being cleared and showing full cart again
  // We can keep a temporary value and clear out only on SHOW with the useEffect
  useEffect(() => {
    if (show) {
      getCartItems(productSku, handleState)
    } else {
      clearCartItems(handleState)
    }
  }, [show, productSku])

  const closeCart = () => showCart(false)

  return (
    <>
      <Portal {...props} isVisible={show} data-visible={show}>
        <>
          <button data-type='close' type='button' onClick={closeCart}>
            <CloseIcon />
          </button>

          <ViewProducts {...state} closeCart={closeCart} />
        </>
      </Portal>
    </>
  )
}

export default CartConnector(Component)