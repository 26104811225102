// This hook replaces code that formerly resided in the
// Header to set SESSION data in the REDUX store.
// This now powers that functionality and also allows
// apps with no header to call it and populate their
// datalayer with SESSION data.

import { useEffect, useState } from 'react'
import { useStore } from 'react-redux'
import { getAllClientData } from 'selectors/clientData'
import { isSessionSet, isAuthenticated } from 'selectors/user/session'
import { initialLoad, revalidateClient } from 'store/middleware/client'

// Adding LOGIC to validate session every 10 minutes
// We will store data in LOCAL STORAGE and fetch on demand
// We will run a timer every 10 seconds to check if we need to FETCH
let timer
const DURATION = 1000 * 30 // ms * s => 30 seconds
const resetTimer = (): void => {
  clearInterval(timer)
  timer = null
}
const startTimer = (callBack: () => void): void => {
  callBack()
  resetTimer()
  timer = setInterval(callBack, DURATION)
}

const useClientSession = () => {
  const [dataSet, handleDataSet] = useState(false)
  const store = useStore()
  const state = { ...store.getState() }
  const hasSession = isSessionSet(state) // Private Page Flag
  const hasAuthenticated = isAuthenticated(state)
  const allClientData = getAllClientData(state)

  const startClientData = () => {
    // Fire on Initial Focus and SetInterval/Timer to repeat action
    startTimer(() => {
      revalidateClient({ store })
    })
  }
  const stopClientData = () => {
    resetTimer()
  }

  useEffect(() => {
    initialLoad({ allClientData, hasSession, store })
    handleDataSet(true)
  }, [])

  useEffect(() => {
    // Lets check as long as user is logged in
    if (hasAuthenticated) {
      window.addEventListener('focus', startClientData)
      window.addEventListener('blur', stopClientData)
      startClientData()
    } else {
      // Stop Checking
      window.removeEventListener('focus', startClientData)
      window.removeEventListener('blur', stopClientData)
      stopClientData()
    }

    return () => {
      window.removeEventListener('focus', startClientData)
      window.removeEventListener('blur', stopClientData)
    }
  }, [dataSet, hasAuthenticated])
}

export default useClientSession
