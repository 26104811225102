import React from 'react'
import styles from './MainTopHeader.module.scss'
import Close from 'svg/close-x.svg'
import UserIcon from 'svg/user.svg'
import { useHeaderV2Context } from 'components/HeaderV2/HeaderV2Context'
import SessionConnector, { ConnectorProps as SessionProps } from 'containers/Session'
import classNames from 'classnames'

const UserTopHeader = ({ fullName }: SessionProps) => {
  const { setSelectedCategory, setMobileHeaderConfig } = useHeaderV2Context()

  const closeAccountMenu = () => {
    setMobileHeaderConfig((previousMobileHeaderConfig) => ({
      ...previousMobileHeaderConfig,
      mobileMenuContent: 'null',
      mobileTopHeader: 'mainTopHeader',
      isMobileMenuOpen: false,
    }))
    setSelectedCategory(null)
  }

  return (
    <>
      <div className={styles.topHeaderMenuAccount}>
        <div onClick={closeAccountMenu}>
          <Close className={styles.closeIcon} />
        </div>
        <div className={styles.user}>
          <UserIcon className={styles.userIcon} />
          <p className={classNames('defaultTypographyH3', styles.userName)}>
            {fullName ? fullName : 'Log In / Register'}
          </p>
        </div>
      </div>
    </>
  )
}

export default SessionConnector(UserTopHeader)
