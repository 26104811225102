import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { Actions as AuthenticationActions } from 'actions/authentication'
import { Actions as SessionActions } from 'actions/user/session'
import { Actions as CartActions } from 'actions/user/cart'
import { Actions as CookieActions } from 'actions/user/cookie'
import { getAuthenticationModal } from 'selectors/authentication'
import { isAuthenticated, isSessionSet } from 'selectors/user/session'
import { Modals } from 'types/Authentication'
import { Actions as ModalActions } from 'actions/shared/modal'
import { getModalName } from 'selectors/shared/modal'

const mapStateToProps = createStructuredSelector({
  isAuthenticated,
  isSessionSet,
  authenticationModal: getAuthenticationModal,
  name: getModalName,
})

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload: Modals) => dispatch(AuthenticationActions.SHOW_MODAL(payload)),
  openModal: (payload: Modals) => dispatch(ModalActions.OPEN_MODAL(payload)),
  updateSession: ({ cart, cookie, session }: Record<string, any>) =>
    dispatch([
      CartActions.SET_CART(cart),
      CookieActions.SET_COOKIE(cookie),
      SessionActions.UPDATE_SESSION(session),
    ]),
})

export type ConnectorProps = {
  isAuthenticated: boolean
  isSessionSet: boolean
  authenticationModal: Modals
  showModal: (...args: Array<any>) => any
  openModal: (...args: Array<any>) => any
  updateSession: (...args: Array<any>) => any
  name: string
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
