import { COMMISSION_RATE, LIMITED_COMMISSION_RATE } from 'lib/product'
import type { Section } from '../types'
export const DEFAULT_APP = '/private/studio/artworks'
export const DEFAULT_URL = '/studio'
// Local Storage Loaded - Once App is Mounted, fetch Form Data from LocalStorage, mark as TRUE
// Current Section - Used to Track where USER is in Form
// Sections - List of ALL Form Sections
// Available Sections - What Section are Enabled/User can Edit
// Valid Sections - Section Form is valid
export const PAGE_DEFAULTS = {
  app: 'saatchi/uploader',
  params: {},
  data: {},
}
// General Values
export const ORIGINAL: string = 'original'
export const OPEN_EDITION: string = 'open-edition'
export const LIMITED_EDITION: string = 'limited-edition'
export const ORIGINAL_TYPE_ID: number = 1
export const OPEN_EDITION_TYPE_ID: number = 2
export const LIMITED_EDITION_TYPE_ID: number = 3
export const OPEN_EDITION_UNITS: number = 9999
export const LIMITED_EDITION_UNITS: number = 10
export const CANVAS_OPTIONS_IDS: Array<string> = ['W38', 'W39']
export const CANVAS_COLORS: Record<string, any> = {
  W38: 'White Canvas',
  W39: 'Black Canvas',
}
export const FRAME_TYPE_IDS: Array<number> = [9, 10, 11]
export const PRINT_AVAILABILITY_DELETED: string = 'product_deleted'
export const PRINT_AVAILABILITY_FOR_SALE: string = 'for-sale'
export const PRINT_AVAILABILITY_ACTIVE: string = 'active'
export const FOR_SALE = 'for-sale'
export const NOT_FOR_SALE = 'not-for-sale'
export const SOLD_OUT = 'sold-out'
export const MATERIAL_CANVAS: string = 'Canvas'
export const LIMITED_FRAME_COLORS = {
  [`9`]: 'Black',
  [`10`]: 'White',
  [`11`]: 'Natural Wood',
  [`0`]: 'None',
}
export const ORIGINAL_FRAME_COLORS: Array<string> = [
  'black',
  'brown',
  'white',
  'silver',
  'gold',
  'patina',
  'other',
]
export const OPEN_EDITION_PRINT_MATERIALS: Array<string> = [
  'Fine Art Paper',
  'Photo Paper',
  'Canvas',
]
export const LIMITED_EDITION_PRINT_MATERIALS: Array<string> = ['Fine Art Paper', 'Photo Paper']
export const PRINT_MATERIALS_INFO = {
  'Fine Art Paper': [
    'Epson Hot Press Bright White',
    '16 mil',
    '100% Cotton',
    'Bright White',
    'Acid-Free',
  ],
  'Photo Paper': [
    'Epson Exhibit Fiber',
    '325gsm',
    'Fiber Photo Paper',
    'Glossy Finish',
    'Wide-Gamut',
  ],
  Canvas: [],
}
// Based on ProductTypeId
export const PROFIT: Record<string, any> = {
  [ORIGINAL_TYPE_ID]: COMMISSION_RATE,
  // original product
  [OPEN_EDITION_TYPE_ID]: COMMISSION_RATE,
  // saatchiart open editions
  [LIMITED_EDITION_TYPE_ID]: LIMITED_COMMISSION_RATE, // limited editions
}
// Form Section State ( VALIDATION status )
export const FORM_SECTION_STATUS = {
  [`0`]: 'INVALID',
  [`1`]: 'UNKNOWN',
  // NEVER been Validated
  [`2`]: 'VALID',
}
// Constants of SECTIONS -> map route ( URL PARAM `:view`  )
export const SECTIONS: Record<string, Section> = {
  ARTWORK_CREATE_MULTI_PRODUCTS: 'all-products',
  ARTWORK_EDIT: 'artwork',
  ARTWORK_IMAGES: 'images',
  ARTWORK_OVERVIEW: 'overview',
  ARTWORK_PRODUCT_SELECTION: 'select-products',
  ARTWORK_UPLOAD: 'upload',
  DASHBOARD: 'dashboard',
  LIMITED_EDITION_PRODUCT: 'limited',
  OPEN_EDITION_PRODUCT: 'prints',
  ORIGINAL_PRODUCT: 'original',
}
export const SUB_SECTIONS: Record<string, string> = {
  // DASHBOARD
  ARTWORKS: 'artworks',
  ADDRESS: 'address',
  HOWTO: 'how-to',
  CURATOR_NOTES: 'curator-notes',
  PROFILE_INFORMATION: 'profile-information',
  ACCOUNT: 'account',
  PROMOTIONS: 'promotions',
}
export const MODALS: Record<string, string> = {
  DELETE_ADDRESS: 'delete_address_modal',
  EDIT_ADDRESS: 'edit_address_modal',
  NEW_ADDRESS: 'new_address_modal',
}
export const KEYWORD_ERROR_MESSAGES = {
  MINIMUM: 'Keywords must be at least 2 characters.',
  UNIQUE: 'Keywords must be unique. Please enter a unique keyword.',
}
// print form names
export const OPEN_EDITION_PRINTS_FORM = 'artwork_open_editions_print_product_form'
export const LIMITED_EDITION_PRINTS_FORM = 'artwork_limited_editions_print_product_form'
export const ARTWORK_EDIT_FORM = 'artwork_edit_form'
// data layer constants
export const ALL_ARTWORKS = 'all artworks'
export const ADDRESSES = 'addresses'
export const PHOTOGRAPHING = 'photographing'