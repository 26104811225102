import React, { useState } from 'react'
import SALink from 'components/SALink'
import type { ConnectorProps as SessionProps } from 'containers/Session'
import SessionConnect from 'containers/Session'
import { getLogout } from 'components/Authentication/apis'
import { DropdownMenu } from './styles'
type Props = SessionProps & {
  onChangeOpen?: (...args: Array<any>) => any
}

const ArtistLinks = (
  props: Record<string, any>
): React.ReactElement<React.ComponentProps<any>, any> => {
  const { profileUrl, userId } = props
  return (
    <>
      <SALink
        data-gtm-event='nav header'
        data-type='account-links'
        href={profileUrl}
        rel='nofollow'
      >
        View Artist Profile
      </SALink>

      <SALink
        data-gtm-event='nav header'
        data-type='account-links'
        href={`/account/collection/${userId}`}
        rel='nofollow'
      >
        Collections
      </SALink>

      <SALink
        data-gtm-event='nav header'
        data-type='account-links'
        href='/accounts/settings'
        rel='nofollow'
      >
        Account
      </SALink>
    </>
  )
}

const UserInfo = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const [dropdownIsHovered, handleDropdownHovered] = useState(false)
  const [dropdownIsOpen, handleDropdownOpen] = useState(false)
  const { fullName, userInitials } = props
  return (
    <div
      data-type='userinfo'
      onMouseOver={() => handleDropdownHovered(true)}
      onMouseLeave={() => handleDropdownHovered(false)}
      onFocus={() => handleDropdownHovered(true)}
      onBlur={() => handleDropdownHovered(false)}
    >
      <button
        type='button'
        data-type='account-info'
        aria-label='authentication links'
        data-auth='true'
        onClick={() => {
          handleDropdownOpen(!dropdownIsOpen)
        }}
      >
        <span data-type='fullname'>{fullName}</span>
        <span data-type='initials'>{userInitials}</span>
      </button>

      <DropdownMenu
        data-type='dropdown'
        {...(dropdownIsOpen
          ? {
              'data-visible': true,
            }
          : {})}
        {...(dropdownIsHovered
          ? {
              'data-hovered': true,
            }
          : {})}
      >
        <h3 data-type='fullname'>{fullName}</h3>

        <ArtistLinks {...props} />

        <span data-type='line' />

        <SALink
          data-gtm-event='nav header'
          data-type='account-links'
          href='/easel_api/auth/logout'
          onClick={async (event: React.SyntheticEvent<HTMLElement>) => {
            event.preventDefault()
            // API will DELETE session and we refresh the page
            await getLogout()
            location.reload()
          }}
          rel='nofollow'
        >
          Log Out
        </SALink>
      </DropdownMenu>
    </div>
  )
}

export default SessionConnect(UserInfo)