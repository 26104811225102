import type { Section } from '../types'
export const DEFAULT_APP = '/private/studio/sales'
export const DEFAULT_URL = '/studio'
export const SECTION = 'sales'
export const SUB_SECTIONS: Record<string, Section> = {
  ACTIVE: 'active',
  ALL: 'all',
  DETAILS: 'details',
}
export const AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION'
export const AWAITING_SHIPMENT = 'AWAITING_SHIPMENT'
export const MISSED_SHIPMENT = 'MISSED_SHIPMENT'
export const PRINT_IN_PRODUCTION = 'PRINT_IN_PRODUCTION'
export const STATUS_COMPLETED = 'COMPLETED'
export const STATUS_DELIVERED = 'DELIVERED'
export const STATUS_IN_TRANSIT = 'IN_TRANSIT'
export const COMPLETED = 'Completed'
export const CONFIRM_AVAILABILITY = 'Confirm Availability'
export const DELIVERED = 'Delivered'
export const IN_PRODUCTION = 'In Production'
export const IN_TRANSIT = 'In Transit'
export const MANAGE_SHIPPING = 'Manage Shipping'
export const RESCHEDULE_SHIPPING = 'Reschedule Shipping'
export const DETAIL_STATUS = {
  [AWAITING_CONFIRMATION]: {
    title: 'Awaiting Confirmation',
    text: 'Please confirm the availability of this artwork as soon as possible.',
    button: {
      enabled: true,
      label: 'Confirm Availability',
      color: 'blue',
      url: '',
    },
  },
  [AWAITING_SHIPMENT]: {
    title: 'Awaiting Shipment',
    text:
      'You have scheduled a shipping date for this artwork. If needed, please click the ‘Manage Shipping’ button to change pick-up/drop-off time.',
    button: {
      enabled: true,
      label: 'Manage Shipment',
      color: 'blue',
      url: '',
    },
  },
  [MISSED_SHIPMENT]: {
    title: 'Missed Shipment',
    text:
      'Our records show that you missed the scheduled hand-off or pickup with the courier. Please reschedule as soon as possible.',
    button: {
      enabled: true,
      label: 'Reschedule Shipping',
      color: 'red',
      url: '',
    },
  },
  [PRINT_IN_PRODUCTION]: {
    title: 'In Production',
    text: 'Your print is currently in production.',
    button: {
      enabled: false,
    },
  },
  [STATUS_COMPLETED]: {
    title: 'Completed',
    text: 'The sale has officially been completed. You may now access your payouts for this order.',
    button: {
      enabled: false,
    },
  },
  [STATUS_DELIVERED]: {
    title: 'Delivered',
    text:
      'Our records show that the order has been delivered to the customer. Your payout will be processed after refund period.',
    button: {
      enabled: false,
    },
  },
  [STATUS_IN_TRANSIT]: {
    title: 'In Transit',
    text:
      'Our records show that the order is currently in transit to be delivered to the customer.',
    button: {
      enabled: false,
    },
  },
}