import React, { FC, ReactNode } from 'react'
import styles from './Alert.module.scss'
import ErrorIcon from 'svg/alert-error.svg'
import InfoIcon from 'svg/alert-info.svg'
import NoticeIcon from 'svg/alert-notice.svg'
import SuccessIcon from 'svg/alert-success.svg'
import WarningIcon from 'svg/alert-warning.svg'
import CloseIcon from 'svg/close.svg'
import classNames from 'classnames'

type AlertProps = {
  icon?: ReactNode
  text?: string
  type?: 'error' | 'info' | 'notice' | 'success' | 'warning'
  cta?: {
    title: string
    onClick: () => void
  }
  onClose?: () => void
  hasBorderRadius?: boolean
}

const Alert: FC<AlertProps> = ({ icon, text, type, cta, onClose, hasBorderRadius = true }) => {
  const getIcon = (typeForIcon, iconProp) => {
    if (iconProp) {
      return iconProp
    }

    switch (typeForIcon) {
      case 'error':
        return <ErrorIcon />

      case 'notice':
        return <NoticeIcon />

      case 'success':
        return <SuccessIcon />

      case 'warning':
        return <WarningIcon />

      case 'info':
      default:
        return <InfoIcon />
    }
  }

  const IconComponent = getIcon(type, icon)

  return (
    <div
      className={classNames(styles.alertContainer, {
        [styles[`${type}Alert`]]: !!type,
        [styles.alertContainerNoBorder]: !!hasBorderRadius,
      })}
      data-type={`alert-notification`}
    >
      <div className={styles.alertBox}>
        <div
          className={classNames(styles.iconBox, {
            [styles.iconBoxWarning]: type === `warning`,
          })}
          data-type='alert-icon'
        >
          {IconComponent}
        </div>
        {text && (
          <span className={styles.alertText} data-type={`alert-message-${type}`}>
            {text}
          </span>
        )}
        {cta && (
          <div className={styles.alertCta} onClick={cta.onClick} data-type={`alert-cta`}>
            {cta.title}
          </div>
        )}
        {onClose && (
          <div className={styles.closeIcon} onClick={onClose}>
            <CloseIcon />
          </div>
        )}
      </div>
    </div>
  )
}

export default Alert
