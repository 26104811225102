// We need a way to create localStorage/replicate response from all-client-data api
// Private pages will already set information into redux
// We want to pull out data and set to Local Storage

import { createSelector } from 'reselect'

import { Shared } from 'types/Shared'
import { User } from 'types/User'

import { getShared } from 'selectors/shared/helpers'
import { getUser } from 'selectors/user/helpers'

export const getAllClientData = createSelector(
  [getShared, getUser],
  (shared: Shared, user: User): Object => {
    const { featureFlags, isBotTraffic, isMobileTraffic, locale, storeLocale } = shared
    const { abTest, cart, cookie, session } = user
    return {
      abTest,
      cart,
      cookie,
      featureFlags,
      isBotTraffic,
      isMobileTraffic,
      locale,
      session,
      storeLocale,
      client: { setup: true },
    }
  }
)
