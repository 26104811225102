import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getUserAvatar } from 'selectors/user/account'
import { getIsAdmin } from 'selectors/user/session'
import { getPageParams } from 'selectors/page'
import { getArtworkId, getSection, getSubSection } from '../selectors/app'
import { getArtistIsAllowedToSell, getReasonsNotAllowedToSell } from '../selectors/artworkForm'
const mapStateToProps = createStructuredSelector({
  artworkId: getArtworkId,
  isAdmin: getIsAdmin,
  section: getSection,
  subSection: getSubSection,
  userAvatar: getUserAvatar,
  isArtistAllowedToSell: getArtistIsAllowedToSell,
  reasonsNotAllowedToSell: getReasonsNotAllowedToSell,
  params: getPageParams,
})
const mapDispatchToProps = null
export type ConnectorProps = {
  artworkId: string
  isAdmin: boolean
  section: string
  subSection: string
  userAvatar: string
  isArtistAllowedToSell: boolean
  reasonsNotAllowedToSell: Array<string>
  params: {
    hitsPerPage?: number | null | undefined
    page?: number | null | undefined
  }
}
export default connect(mapStateToProps, mapDispatchToProps)