import React, { useEffect, useState } from 'react'
import { updateMyUserPreferences } from 'apis/account/userPreferences'
import useFormData from 'hooks/formData'
import CountryInput from './CountryInput'
import CurrencyInput from './CurrencyInput'
import MeasurementInput from './MeasurementInput'
import { setUserPreferencesCookie } from '../apis'
import { preferenceForm, preferencesRules } from '../helpers'
import styles from '../Modals/Preferences.module.scss'

type Props = {
  availableCountries: Record<string, any>
  currenciesDropdownData: Record<string, any>
  userPreferences: Record<string, any>
  onSubmit: (...args: Array<any>) => any
} & Record<string, any>

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    availableCountries,
    currenciesDropdownData,
    isAuthenticated,
    userPreferences,
    onSubmit,
  } = props
  const [state, handleState] = useState({
    isBlocked: false,
  })
  const { isBlocked } = state
  const { formData, formErrors, onChangeInput, validateFormData } = useFormData({
    initialState: { ...preferenceForm, ...userPreferences },
    formRules: preferencesRules,
  })
  const { country, language, currency, measurementUnit } = formData
  const {
    country: countryError,
    // language: languageError,
    currency: currencyError,
    measurementUnit: measurementUnitError,
  } = formErrors
  useEffect(() => {
    window.dataLayer.push({
      event: 'user preferences start',
    })
  }, [])

  const handleSubmit = async (ev: React.SyntheticEvent<HTMLInputElement>) => {
    ev.preventDefault()
    const validForm = await validateFormData()

    if (!validForm) {
      return
    }

    handleState({ ...state, isBlocked: true })

    // If authenticated write USER preferences - Gallery Endpoint
    if (isAuthenticated) {
      await updateMyUserPreferences({
        country,
        currency,
        measurementUnit,
      })
    }

    const { payload, messages, success } = await setUserPreferencesCookie({
      country,
      language,
      currency,
      measurementUnit,
    })

    if (!success) {
      handleState({
        ...state,
        isBlocked: false,
        error: (messages || []).join(','), // Array[ 'error message' ]
      })
      window.dataLayer.push({
        event: 'user preferences error',
      })
      return
    }

    window.dataLayer.push({
      event: 'user preferences success',
    })
    onSubmit(payload)
  }

  return (
    <form noValidate onSubmit={handleSubmit}>
      {isBlocked && <div className={styles.loader} />}

      <p className={`${styles.title} defaultTypographyH3`}>Choose Your Preferences</p>

      <fieldset className='defaultFieldset'>
        <CountryInput
          data={availableCountries}
          error={countryError}
          onChange={onChangeInput}
          selected={country}
        />

        <CurrencyInput
          data={currenciesDropdownData}
          error={currencyError}
          onChange={onChangeInput}
          selected={currency}
        />

        <MeasurementInput
          error={measurementUnitError}
          onChange={onChangeInput}
          selected={measurementUnit}
        />

        <button type='submit' data-action='log-in' className='defaultButtonGunMetal'>
          <p className='defaultTypographyCtaLarge defaultTextUppercase'>Update Preferences</p>
        </button>
      </fieldset>
    </form>
  )
}

export default Component
