import { NavigationLinksArray } from './NavigationLinks.types'

// Debounce || SetTimer Callback
let timer
const timerDuration = 1500 // 1000 = 1 second

const clearTimer = () => {
  clearTimeout(timer)
  timer = null
}

// Added a third optional param to stop the timer and abort the debounce
// if user mouses out before the timer completes
export const debounce = (
  callback: (...args: Array<any>) => any,
  duration?: number,
  abort?: boolean
) => {
  if (abort) {
    clearTimer()
    return
  }

  clearTimer()
  timer = setTimeout(() => {
    callback()
  }, duration || timerDuration)
}

export const transformNavLinks = ({
  navigationLinks,
  transformFunction,
}: {
  navigationLinks: NavigationLinksArray
  transformFunction: (navigationLinks: NavigationLinksArray) => NavigationLinksArray
}): NavigationLinksArray => {
  return transformFunction(navigationLinks)
}

export const abTestInSubNavLinks = ({
  navigationLinks,
  abTest,
}: {
  navigationLinks: NavigationLinksArray
  abTest: Record<string, any>
}): NavigationLinksArray => {
  const replaceSubNavLinkUrl = (url: string) => {
    const AALanderABTest = abTest[38]
    const LEGACY = '01'

    switch (url) {
      case '/artadvisory/brief':
        return AALanderABTest === LEGACY ? url : '/artadvisory#contact-us'

      default:
        return url
    }
  }

  return navigationLinks.map(({ links, ...navigationLinksRest }) => {
    return {
      ...navigationLinksRest,
      links: links.map(({ links: subNavLinks, ...subNavigationLinksRest }) => {
        return {
          ...subNavigationLinksRest,
          links: subNavLinks.map(({ url, ...rest }) => ({
            ...rest,
            url: replaceSubNavLinkUrl(url),
          })),
        }
      }),
    }
  })
}
