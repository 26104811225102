import React from 'react'
import styles from './MainTopHeader.module.scss'
import accountStyles from '../../IconActions/User/Account.module.scss'
import Back from 'svg/caret-left.svg'
import UserIcon from 'svg/user.svg'
import { useHeaderV2Context } from 'components/HeaderV2/HeaderV2Context'
import SessionConnector, { ConnectorProps as SessionProps } from 'containers/Session'
import classNames from 'classnames'

const MainTopHeader = ({ fullName, userInitials, authenticated }: SessionProps) => {
  const { setSelectedCategory, setMobileHeaderConfig } = useHeaderV2Context()

  const backToCategoriesMenu = () => {
    setMobileHeaderConfig((previousMobileHeaderConfig) => ({
      ...previousMobileHeaderConfig,
      mobileMenuContent: 'categories',
      mobileTopHeader: 'mainTopHeader',
    }))
    setSelectedCategory(null)
  }

  return (
    <>
      <div className={styles.topHeaderMenuAccount}>
        <div onClick={backToCategoriesMenu}>
          <Back className={styles.closeIcon} />
        </div>
        <div className={styles.user}>
          {authenticated ? (
            <span className={accountStyles.accountIconInitials}>{userInitials}</span>
          ) : (
            <UserIcon className={styles.userIcon} />
          )}
          <p className={classNames('defaultTypographyH5', styles.userName)}>
            {fullName ? fullName : 'Log In / Register'}
          </p>
        </div>
      </div>
    </>
  )
}

export default SessionConnector(MainTopHeader)
