import React from 'react'
import styles from './CategoriesCTA.module.scss'
import classNames from 'classnames'
import { useHeaderV2Context } from '../../HeaderV2Context'
import SALink from 'components/SALink'
import { FEATURED_CATEGORIES_MAPPING } from '../../NavigationLinks'

interface CategoriesHoverContainerProps {
  containerRef: React.RefObject<HTMLDivElement>
}

const CategoriesHoverContainer = ({ containerRef }: CategoriesHoverContainerProps) => {
  const { selectedCategory, navigationLinks } = useHeaderV2Context()

  const navigationCategory = navigationLinks.find(({ title }) => title === selectedCategory)
  const hasSubcategories = navigationCategory?.links?.length > 0

  return (
    hasSubcategories && (
      <div
        ref={containerRef}
        className={classNames(styles.categoriesHoverContainer, {
          [styles.categoriesHoverContainerHover]: !!selectedCategory,
        })}
      >
        <div className={styles.categoriesHoverOptions}>
          {navigationLinks.map(({ title: categoryTitle, links }) =>
            links.map(({ title, links: subcategoryLinks }, index) => (
              <div
                className={classNames(styles.categoriesHoverOptionsSubcategories, {
                  [styles.categoriesHoverOptionsSubcategoriesSelected]:
                    selectedCategory === categoryTitle,
                })}
                key={`${title}${index.toString()}`}
              >
                <span className='defaultTypographyH6'>{title}</span>
                {subcategoryLinks.map(({ title: optionTitle, url: optionUrl }, index) => (
                  <SALink
                    key={`${optionTitle}${index}`}
                    className={classNames('defaultTypographyXSmall', 'defaultLink')}
                    href={optionUrl}
                    data-gtm-event='top-nav'
                  >
                    {optionTitle}
                  </SALink>
                ))}
              </div>
            ))
          )}
        </div>
        <div>
          {navigationLinks.map(({ title: categoryTitleImage, image }, index) => (
            <SALink
              className={classNames('defaultLink', styles.categoriesHoverImageContainer, {
                [styles.categoriesHoverImageContainerSelected]:
                  selectedCategory === categoryTitleImage,
              })}
              href={image.linkTo}
              data-gtm-event='top-nav'
              key={index.toString()}
            >
              <img src={image.url} />
              <div>
                <p
                  className={classNames(
                    'defaultTypographyCtaMedium',
                    styles.categoriesHoverImageText
                  )}
                >
                  {FEATURED_CATEGORIES_MAPPING[categoryTitleImage || '']}
                </p>
              </div>
            </SALink>
          ))}
        </div>
      </div>
    )
  )
}

export default CategoriesHoverContainer
